import { PetuCustomer } from '@/classes/customer'

export const customersInfo = {
    namespaced: true,
    state: {
        customers: [],
        total: 0,
        query: '',
        isActive: false,
        filterBy: 0,
        nextToken: undefined,
    },
    mutations: {
        setCustomers(state, payload) {
            state.customers = payload
        },
        setTotal(state, payload) {
            state.total = payload
        },
        setToken(state, payload) {
            state.nextToken = payload
        },
        setQuery(state, payload) {
            state.query = payload;
        },
        setIsActive(state, payload) {
            state.isActive = payload;
        },
        setFilterBy(state, payload) {
            state.filterBy = payload;
        }
    },
    actions: {
        async getCustomersData({ commit }) {
            let petuCustomer = new PetuCustomer();
            const customersData = await petuCustomer.getList({});
            commit('setCustomers', customersData);
        },
        async getCustomersWithImages({ commit }) {
            return new Promise(async (resolve, reject) => {
                const customers = await (new PetuCustomer()).getList()
                for(let c of customers) {
                    if (c.profilePic != null && c.profilePic.key.length > 0) {
                        let cc = new PetuCustomer(c)
                        await cc.getProfilePicSrc()
                        c.img = cc.image.imgUrl
                    }
                }

                commit('setCustomers', customers)
                resolve(customers)
            })
        },
        async getCustomers({ commit, state }, limit) {
            let c = new PetuCustomer();

            if( state.total > 0 && state.customers.length >= state.total )
                return;

            let data = await c.getListLimit( limit, state.customers.length );

            // items.forEach(async (i) => {
            //     if( i.profilePic != null && i.profilePic.key.length ) {
            //         const cc    = new PetuCustomer(i);
            //         await cc.getProfilePicSrc()
            //         i.img       = cc.image.imgUrl
            //     }
            // })

            commit( 'setCustomers', data.items );
            commit( 'setTotal', data.total );


            // return new Promise(async (resolve, reject) => {
            //     const c     = new PetuCustomer()
            //     limit       = limit ? limit : 200
            //     const args  = { limit, nextToken: getters.nextToken }

            //     if (q) {
            //         args.filter = {
            //             // searchString : { contains: q }
            //             or: [
            //                 { firstName: { contains: q } },
            //                 { lastName: { contains: q } },
            //                 { email: { contains: q } }
            //             ]
            //         };
            //         limit = 9999;
            //     }

            //     console.log(q);

            //     let items  = await c.getList();
                
            //     items.forEach(async (i) => {
            //         if( i.profilePic != null && i.profilePic.key.length ) {
            //             const cc    = new PetuCustomer(i);
            //             await cc.getProfilePicSrc()
            //             i.img       = cc.image.imgUrl
            //         }
            //     })

            //     resolve({ items, nextToken })
            // })
        },
        setQuery({ commit }, query) {
            commit('setQuery', query);
        },
        setActive({ commit }, isActive) {
            commit('setIsActive', isActive);
        },
        setFilterBy({ commit }, filterBy) {
            commit('setFilterBy', filterBy);
        }
    },
    getters: {
        customers   : (state) => state.customers,
        nextToken   : (state) => state.nextToken,
        query       : (state) => state.query,
        isActive    : (state) => state.isActive,
        filterBy    : (state) => state.filterBy
    }
}