// import { PetuCheckIn } from '../../classes/checkIn'

export const checkInsInfo = {
    namespaced: true,
    state: {
        checkIns    : null,
    },
    mutations: {
        setCheckIns( state, payload ) {
            state.checkIns = payload;
        },
    },
    actions: {
        // async getCheckInsData({ commit }) {
        //     let petuCheckIn = new PetuCheckIn();
        //     const checkInsData = await petuCheckIn.getList();
        //     commit( 'setCheckIns', checkInsData );
        // },
    },
    getters: {
        checkIns: (state) => state.checkIns
    }
}