<template>
    <div>
        <v-bottom-sheet v-model="sheet">
            <v-card tile>
                <v-card-text class="px-0">
                    <div v-if="cart != null && products.length > 0">
                        <v-list>
                            <v-subheader>Productos en el carrito</v-subheader>
                            <swipe-list ref="list" :items="cart.products" item-key="id" class="card">
                                <template v-slot="{ item }">
                                    
                                    <v-list-item ref="content" class="card-content">
                                        <v-badge :content="item.qty" color="primary" bordered overlap offset-x="25" offset-y="20" left>
                                            <v-list-item-avatar>
                                                <v-avatar>
                                                    <v-img :src="getProductImage( item.productId )"></v-img>
                                                </v-avatar>
                                            </v-list-item-avatar>
                                        </v-badge>
                                        
                                        <v-list-item-content>
                                            <v-list-item-title>{{ getProductName( item.productId ) }}</v-list-item-title>
                                            <v-list-item-subtitle>{{ getProducPrice( item.productId ) }}</v-list-item-subtitle>
                                        </v-list-item-content>
                                        
                                        <v-list-item-action>
                                            <div>{{ getProductTotal( item ) }}</div>
                                        </v-list-item-action>
                                    </v-list-item>
                                    
                                </template>

                                <!-- left swipe side template and v-slot:left="{ item }" is the item clearly -->
                                <!-- remove if you dont wanna have left swipe side  -->
                                <!-- <template v-slot:left="{ item, close }">
                                    <div class="swipeout-action red" title="remove" @click="removeProduct(item)">
                                        <v-icon dark>mdi-delete</v-icon>
                                    </div>
                                    <div class="swipeout-action purple" @click="close">
                                        <v-icon dark>mdi-close</v-icon>
                                    </div>
                                </template> -->

                                <!-- right swipe side template and v-slot:right"{ item }" is the item clearly -->
                                <!-- remove if you dont wanna have right swipe side  -->
                                <template v-slot:right="{ item, close }">
                                    <div class="swipeout-action red" @click="removeItem( item )">
                                        <!-- place icon here or what ever you want -->
                                        <v-icon dark>mdi-delete</v-icon>
                                    </div>
                                    <div class="swipeout-action orange" @click="openQtyDialog( item )">
                                        <!-- place icon here or what ever you want -->
                                        <v-icon dark>mdi-numeric</v-icon>
                                    </div>
                                    <div class="swipeout-action purple" @click="close">
                                        <!-- place icon here or what ever you want -->
                                        <v-icon dark>mdi-close</v-icon>
                                    </div>
                                </template>
                            </swipe-list>
                        </v-list>

                        <v-divider></v-divider>

                        <v-row class="mx-3 mt-1">
                            <v-col cols="6" class="px-0 pb-0">Subtotal</v-col>
                            <v-col cols="6" class="text-right px-0 pb-0">{{ getCartSubtotal() }}</v-col>

                            <v-col cols="6" class="px-0 pb-0" v-if="couponVisible">Coupons</v-col>
                            <v-col cols="6" class="text-right px-0 pb-0" v-if="couponVisible"> - {{ getCartDiscounts() }}</v-col>

                            <!-- <v-col cols="12" class="text-right px-0 pb-0" v-if="couponVisible">
                                <template v-for="(coupon, ix) in coupons">
                                    <v-col cols="2" class="pt-0 pb-1">
                                        <v-btn icon small text color="red" @click="removeCoupon(coupon)"><v-icon small>mdi-close</v-icon></v-btn>
                                    </v-col>
                                    <v-col cols="6" class="pt-0 pb-1" align-self="center">
                                        <span class="awesome-mini-cart-drawer-text">{{ coupon.code }}</span>
                                    </v-col>
                                    <v-col cols="4" class="text-right pt-0 pb-1" align-self="center" style="border-right: 2px solid #cdcdcd;">
                                        <span class="awesome-mini-cart-drawer-text">- {{ formatMoney(coupon.amount) }}</span>
                                    </v-col>
                                </template>
                            </v-col> -->

                            <v-col cols="6" class="px-0">Tax</v-col>
                            <v-col cols="6" class="text-right px-0">{{ getCartTax() }}</v-col>

                            <v-col cols="6" class="px-0 title">Total</v-col>
                            <v-col cols="6" class="text-right  px-0 title">{{ getCartTotal() }}</v-col>

                            <v-col cols="12" class="pa-0">
                                <v-btn block depressed color="primary" class="white--text mt-2" style="text-transform:none;" to="/checkout" @click="sheet = false">Checkout</v-btn>
                            </v-col>
                        </v-row>
                    </div>

                    <div class="text-center pt-4" v-else>
                        <v-icon size="48">mdi-cart-outline</v-icon>
                        <div class="title pt-3" style="font-weight:400">El carrito está vacío</div>
                    </div>
                </v-card-text>
            </v-card>
        </v-bottom-sheet>

        <v-dialog v-model="qtyDialog" width="300">
            <v-card>
                <v-card-title class="headline">Update qty</v-card-title>
                <v-card-text v-if="selected != null">
                    <v-text-field v-model="tempQty" placeholder="qty" dense hide-details outlined @keydown="intOnly" type="number"></v-text-field>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn depressed style="text-transform:none" @click="closeQtyDialog()">Cancel</v-btn>
                    <v-btn color="primary" depressed style="text-transform:none" @click="changeQty" :loading="saving">Update</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { PetuProduct } from '../classes/product';
import { _st } from '../softech';
import { SwipeList } from 'vue-swipe-actions';
import { State } from '@/models';

export default {
    props: {
        value               : { type: Boolean, default: false }
    },
    data() {
        return {
            sheet           : this.value,
            productImages   : [],

            selected        : null,
            saving          : false,
            tempQty         : 1,
            qtyDialog       : false,
        }
    },
    computed: {
        ...mapGetters({
            user        : 'auth/user',
            cart        : 'cartInfo/cart',
            products    : 'cartInfo/cartProducts',
            coupons     : 'cartInfo/cartCoupons',
            subtotal    : 'cartInfo/cartSubtotal',
            tax         : 'cartInfo/cartTax',
            shipping    : 'cartInfo/cartShipping',
            discounts   : 'cartInfo/cartDiscounts',
            total       : 'cartInfo/cartTotal',
        }),
        couponVisible() {
            return this.coupons.length > 0;
        }
    },
    watch: {
        value( v ) {
            this.sheet = v;
        },
        sheet( v ) {
            this.$emit( 'input', v );
        },
        async products() {
            for( let p of this.products ) {
                let pp = new PetuProduct( p );
                let src = await pp.getProductImage();
                this.productImages.push({
                    id      : pp.data.id,
                    src     : src
                });
            }
        }
    },
    async mounted() {
        await this.loadCart();
    },
    methods: {
        ...mapActions({
            loadCart        : 'cartInfo/getCartData',
            setQty          : 'cartInfo/setQty',
            removeProduct   : 'cartInfo/removeProduct',
        }),
        getProductImage( productId ) {
            let p = this.productImages.find(p => {
                return p.id == productId
            });

            return p?.src;
        },
        getProductName( productId ) {
            let product = this.products.find(p => {
                return p.id == productId;
            });

            if( _st.isNU( product ) )
                return '';

            if( product.state == State.DRAFT ) {
                this.removeProduct( productId );
                return;
            }

            if( _st.isNU( product ) ) return '';
            return product.name;
        },
        getProducPrice( productId ) {
            let product = this.products.find(p => {
                return p.id == productId;
            });

            if( _st.isNU( product ) ) return _st.toMoney( 0 );

            let p = new PetuProduct( product );
            return _st.toMoney( p.getPrice() );
        },
        getProductTotal( product ) {
            let prod = this.products.find(p => {
                return p.id == product.productId;
            });

            if( _st.isNU( prod ) ) return _st.toMoney( 0 );

            let p = new PetuProduct( prod );
            return _st.toMoney( p.getPrice() * product.qty );
        },
        getCartSubtotal() {
            return _st.toMoney( this.subtotal );
        },
        getCartTax() {
            return _st.toMoney( this.tax );
        },
        getCartDiscounts() {
            return _st.toMoney( this.discounts );
        },
        getCartTotal() {
            return _st.toMoney( this.total );
        },
        async removeItem( item ) {
            await this.removeProduct( item.productId );

            this.closeQtyDialog();
        },
        async changeQty() {
            this.saving = true;
            await this.setQty({
                productId: this.selected.productId,
                qty: this.tempQty
            });
            this.saving = false;

            this.closeQtyDialog();
        },
        openQtyDialog( item ) {
            this.selected = item;
            this.tempQty = item.qty;
            this.qtyDialog = true;
        },
        closeQtyDialog() {
            this.qtyDialog = false;
            this.selected = null;
            this.tempQty = 1;
        },
        intOnly(e) {
            const key       = e.keyCode;
            const ctrlFlag  = e.ctrlKey || e.metaKey;
            
            if ([46, 8, 9, 27, 13].includes(key) ||    // allow: backspace, delete, tab, escape and enter
                (key == 65 && ctrlFlag) ||                  // allow: ctrl/cmd+A
                (key == 67 && ctrlFlag) ||                  // allow: ctrl/cmd+C
                (key == 88 && ctrlFlag) ||                  // allow: ctrl/cmd+X
                (key >= 35 && key <= 39)) {                 // allow: home, end, left, right
                return;                                     // do nothing
            }
      
            /** if it's a number, stop the keypress */
            if ((e.shiftKey || (key < 48 || key > 57)) && (key < 96 || key > 105)) {
                e.preventDefault();
            }
        },
    },
    components: {
        SwipeList
    }
}
</script>

<style lang="scss">
// este CSS se sacó de node_modules/vue-swipe-actions/dist/vue-swipe-actions.css
.swipeout {
	position: relative;
	overflow: hidden;
	display: flex;
}

.swipeout .swipeout-left, .swipeout .swipeout-right {
	position: absolute;
	height: 100%;
	display: flex;
	z-index: 1;
}

.swipeout .swipeout-left {
	left: 0;
	transform: translateX(-100%);
}

.swipeout .swipeout-right {
	right: 0;
	transform: translateX(100%);
}

.swipeout .swipeout-content,
.swipeout .swipeout-action {
	transition: transform .2s;
	will-change: transform;
}

.swipeout.swipeout--no-transition .swipeout-content,
.swipeout.swipeout--no-transition .swipeout-action {
  transition: none !important;
}

.swipeout .swipeout-content {
	width: 100%;
}

.swipeout-non-selectable {
	-webkit-user-select: none !important;
    -moz-user-select: none !important;
    -ms-user-select: none !important;
    user-select: none !important;
}

.swipeout-no-pointer-events {
	pointer-events: none !important;
}

/* SwipeList */

.swipeout-list {
	display: flex;
	flex-direction: column;
}

.swipeout-list-item {
	flex: 1;
}
</style>

<style lang="scss" scoped>
/* app specific styles */
.swipeout-action {
    display: flex;
    align-items: center;
    padding: 0 1rem;
    cursor: pointer;
    left: 0;
}
/* https://developer.apple.com/design/human-interface-guidelines/ios/visual-design/color/ */
.swipeout-action.blue {
    color: white;
    background-color: rgb(0, 122, 255);
}
.swipeout-action.blue:hover {
    background-color: darken(rgb(0, 122, 255), 5%);
}
.swipeout-action.purple {
    color: white;
    background-color: rgb(88, 86, 214);
}
.swipeout-action.purple:hover {
    background-color: darken(rgb(88, 86, 214), 5%);
}
.swipeout-action.red {
    color: white;
    background-color: rgb(255, 59, 48);
}
.swipeout-action.red:hover {
    background-color: darken(rgb(255, 59, 48), 5%);
}
.swipeout-action.green {
    color: white;
    background-color: rgb(76, 217, 100);
}
.swipeout-action.green:hover {
	background-color: darken(rgb(76, 217, 100), 5%);
}
.swipre-action.orange {
    color: white;
    background-color: #f68926;
}
.swipeout-list-item {
  flex: 1;
  border-bottom: 1px solid lightgray;
}
.swipeout-list-item:last-of-type {
    border-bottom: none;
}
.card {
  width: 100%;
  box-shadow: none;
}
.transition-right {
	transform: translate3d(100%, 0 ,0) !important;
}
.transition-left {
	transform: translate3d(-100%, 0 ,0) !important;
}
.toolbar {
	display: flex;
	align-items: center;
}
.toolbar .toolbar-section {
	flex: 0 0 auto;
}
.toolbar .toolbar-section--center {
	flex: 1000 1 0%;
}
</style>