/*
 * Author: Roberto Torres
 * Company: Softech Corporation
 * Version: 1.0.0
 * Date: 2021-05-19
 * 
 * Description: 
 * Class to facilitate the creation and manipulation of 
 * customer cart in the Petu App
 * 
 */

import { API } from '@/inc/api';
import { _st } from '@/softech';
import store from '@/store';
// import { createCart, updateCart } from '@/graphql/mutations';
// import { getCart, listProducts, listCoupons, customerCart } from '@/graphql/queries';
import { PetuProduct } from './product';
import { PetuCoupon } from './coupon';
import { CouponType } from '../models';
// import moment from 'moment';

export class PetuCart {
    constructor( cart = null ) {
        if( _st.isNU( cart ) )
            this.data = {
                id                      : 0,
                customerId              : store.getters['auth/customer']?.id, // 048ddd4c-f898-4df0-a93b-57d8607ed950
                products                : [],
                coupons                 : [],
                dateCreated             : null,
                lastUpdate              : null
            };
        else
            this.data = cart;

        this.products = [];
        this.coupons = [];
    }

    async load( cartId = null ) {
        if( _st.isEmpty( cartId ) ) {
            // load cart from localStorage, if exist
            let cart = localStorage.getItem( 'petu-cart' );
            if( !_st.isNUE( cart ) ) {
                this.data = JSON.parse( cart );
                return;
            }

            // console.error( 'Cart ID cannot be empty.' );
            return;
        }

        // load cart from DB
        // const p = await API.graphql( graphqlOperation( getCart, { id: cartId } ) );
        // this.data = p.data.getCart;

        try {
            let api = new API();
            let res = await api.get(`/cart/id/${btoa(cartId)}`);

            if( res.data.status !== true ) {
                return res;
            }

            this.data = res.data.data;
        }
        catch( error ) {
            return Promise.reject( error );
        }
    }
    async loadFromCustomerId( customerId = null ) {
        
        if( _st.isEmpty( customerId ) ) {
            console.error( 'Customer ID cannot be empty.' );
            return;
        }

        // load cart from DB
        // const p = await API.graphql( graphqlOperation( customerCart, { customerId: customerId } ) );
        try {
            let api = new API();
            let res = await api.get(`/cart/customer/${btoa(customerId)}`);

            if( res.data.status !== true ) {
                return Promise.reject( res );
            }

            this.data = res.data.data;
            this.data.customerId = customerId;
        }
        catch( error ) {
            return Promise.reject( error );
        }
    }
    async addProduct( productId = null, qty = 1 ) {
        if( _st.isEmpty( productId ) ) {
            console.error( 'Product ID cannot be empty.' );
            return;
        }

        // check if product is already in the cart
        let product = this.data.products.find(p => {
            return p.productId == productId;
        });

        if( !_st.isNU( product ) )
            product.qty += parseInt( qty );
        else
            this.data.products.push( { productId, qty } );

        await this.save();
    }
    async removeProduct( productId ) {
        if( _st.isEmpty( productId ) ) {
            console.error( 'Product ID cannot be empty.' );
            return;
        }

        let ix = this.data.products.findIndex(p => {
            return p.productId == productId;
        });

        if( _st.isNU( ix ) )
            return;

        this.data.products.splice( ix, 1 );
        await this.save();
    }
    async setQty( productId, qty = 1 ) {
        if( _st.isEmpty( productId ) ) {
            console.error( 'Product ID cannot be empty.' );
            return;
        }

        let product = this.data.products.find(p => {
            return p.productId == productId;
        });

        if( _st.isNU( product ) )
            return;

        product.qty = qty;
        await this.save();
    }
    async addCoupon( couponId ) {
        if( _st.isEmpty( couponId ) ) {
            console.error( 'Coupon ID cannot be empty.' );
            return;
        }

        // CHECK IF CAN ADD COUPON
        if( this.data.coupons.includes( couponId ) )
            return 'Coupon already applied';

        let coupon = new PetuCoupon();
        await coupon.load( couponId );
        if( !await coupon.isValidForCart( this ) )
            return coupon.invalidReason;

        this.data.coupons.push( couponId );
        await this.save();
    }
    async removeCoupon( couponId ) {
        if( _st.isEmpty( couponId ) ) {
            console.error( 'Coupon ID cannot be empty.' );
            return;
        }

        let ix = this.data.coupons.findIndex(c => {
            return c.id == couponId;
        });

        if( _st.isNU( ix ) )
            return;

        this.data.coupons.splice( ix, 1 );
        await this.save();
    }
    async clear() {
        this.data.products = [];
        this.data.coupons = [];
        this.products =[];
        this.coupons = [];
        await this.save();
    }
    async save() {
        // let now = this.now();

        // delete properties that are not allowed on the update
        // delete this.data.createdAt;
        // delete this.data.updatedAt;

        // if there is no customer logged in, save the cart in localstorage
        if( _st.isEmpty( this.data.customerId ) || this.data.customerid == 0 ) {
            localStorage.setItem('petu-cart', JSON.stringify( this.data ) );
            return;
        }

        // create new cart
        // if( _st.isNUE( this.data.id ) ) {
        //     this.data.dateCreated = now;
        //     this.data.lastUpdate = now;
        //     // this.data.id = uuidv4();
        //     // const p = await API.graphql( graphqlOperation( createCart, { input: this.data } ) );

        //     // this.data = p.data.createCart;
        // }

        // update existing cart
        // else {
        //     this.data.lastUpdate = now;
        //     await API.graphql( graphqlOperation( updateCart, { input: this.data } ) );
        // }

        try {
            let api = new API();
            let res = await api.post('/cart', this.data );

            if( res.data.status !== true )
                return Promise.reject( res );
        }
        catch( error ) {
            return Promise.reject( error );
        }
    }

    // merge carts from localStorage and the newly singed in customer
    async assignOrphanCart( customerId = null ) {
        if( _st.isEmpty( customerId ) ) {
            console.error( 'Customer ID cannot be empty.' );
            return;
        }

        this.data.customerId = customerId;
        let orphanCart = {
            products: this.data.products,
            coupons: this.data.coupons
        };

        await this.loadFromCustomerId( customerId );
        for( let prod of orphanCart.products ) {
            let product = this.data.products.find(p => {
                return p.productId == prod.productId;
            });
    
            if( !_st.isNU( product ) )
                product.qty += parseInt( prod.qty );
            else
                this.data.products.push( { productId: prod.productId, qty: prod.qty } );
        }

        for( let couponId of orphanCart.coupons ) {
            if( this.data.coupons.includes( couponId ) )
                continue;

            let coupon = new PetuCoupon();
            await coupon.load( couponId );
            if( !await coupon.isValidForCart( this ) )
                continue;

            this.data.coupons.push( couponId );
        }

        await this.save();

        // remove the local storage cart item
        localStorage.removeItem('petu-cart');
    }
    isInCart( productId ) {
        let product = this.data.products.find(p => {
            return p.productId == productId;
        });

        return !_st.isNU( product );
    }
    async loadProducts() {
        let prodIds = _st.extractProp( this.data?.products, 'productId' );
        let filters = [];

        // return if no products has been found
        if( prodIds.length == 0 )
            return [];

        prodIds.forEach(pId => {
            filters.push({ id: { eq: pId } });
        });

        // const p = await API.graphql( { query: listProducts, variables: { filter: { or: filters } }, authMode: 'API_KEY' } );
        this.products = await ( new PetuProduct() ).getList( prodIds.join(',') );

        return this.products;
    }
    async loadCoupons() {
        let filter = [];

        // return if no products has been found
        if( this.data?.coupons.length == 0 )
            return [];

            this.data?.coupons.forEach(cId => {
            filter.push({ id: { eq: cId } });
        });

        // falta hacer esto ***
        // const c = await API.graphql( { query: listCoupons, variables: { filter: { and: filter } }, authMode: 'API_KEY' } );

        // this.coupons = c.data.listCoupons.items;
        return this.coupons;
    }
    async getProductData( productId ) {
        if( this.data.products.length > 0 && this.products.length != this.data.products.length )
            await this.loadProducts();

        return this.products.find(p => {
            return p.id == productId;
        })
    }
    async getSubtotal() {
        let subtotal = 0;
        if( this.data.products.length > 0 && this.products.length != this.data.products.length )
            await this.loadProducts();

        this.products.forEach(p => {
            let petuProduct = new PetuProduct( p );
            let cartProduct = this.data?.products.find(cp => {
                return cp.productId == p.id;
            });

            if( _st.isNU( cartProduct ) ) return;

            subtotal += petuProduct.isPurchasable() ? petuProduct.getPrice() * cartProduct.qty : 0;
        });

        return subtotal;
    }
    async getTax() {
        let tax = 0;
        if( this.data.products.length > 0 && this.products.length != this.data.products.length )
            await this.loadProducts();

        this.products.forEach(p => {
            let petuProduct = new PetuProduct( p );
            let cartProduct = this.data?.products.find(cp => {
                return cp.productId == p.id;
            });

            if( _st.isNU( cartProduct ) ) return;

            /**
             * CAMBIAR EL TAX HARDCODED POR UNA VARIABLE CONFIGURABLE
             */
            tax += petuProduct.isPurchasable() && petuProduct.data.isTaxable ? petuProduct.getPrice() * cartProduct.qty : 0; 
        });

        tax = ( tax - await this.getDiscounts() ) * 0.115;

        return tax;
    }
    getShipping() {
        return 0;
    }
    async getDiscounts() {
        let total = 0;
        let coupons = await this.loadCoupons();

        for(let c of coupons) {
            if( c.type == CouponType.AMOUNT )
                total += c.amount;
            else if( c.type == CouponType.PERCENTAGE ) {
                let subtotal = await this.getSubtotal();
                total += subtotal * ( c.amount / 100 );
            }
        }

        return total;
    }
    async getTotal() {
        if( this.data.products.length > 0 && this.products.length != this.data.products.length )
            await this.loadProducts();
        if( this.data.coupons.length > 0 && this.coupons.length != this.data.coupons.length )
            await this.loadCoupons();

        return await this.getSubtotal() + await this.getTax() + this.getShipping() - await this.getDiscounts();
    }
    async needShipping() {
        if( this.data.products.length > 0 && this.products.length != this.data.products.length )
            await this.loadProducts();

        for( let p of this.products ) {
            let petuProduct = new PetuProduct( p );
            if( petuProduct.needShipping() === true )
                return true;
        }

        return false;
    }
    getCartQty() {
        return _st.sum( this.data.products, 'qty' );
    }

    /**
     * Check if cart products and coupons are valid
     * @returns true when cart is valid, string array when errors are found
     */
    async validate() {
        let errors = [];
        if( this.data.products.length > 0 && this.products.length != this.data.products.length )
            await this.loadProducts();
        if( this.data.coupons.length > 0 && this.coupons.length != this.data.coupons.length )
            await this.loadCoupons();

        // check if all products are available and in stock
        this.data.products.forEach(p => {
            let petuProduct = new PetuProduct( p );
            if( !petuProduct.isPurchasable() )
                errors.push( `${petuProduct.data.name} no está disponible.` );
        });

        // check if the coupons are valid for the cart
        this.data.coupons.forEach(c => {
            let petuCoupon = new PetuCoupon( c );
            if( !petuCoupon.isUsable() && petuCoupon.isValidForCart( this ) )
                errors.push( `Coupon ${petuCoupon.data.code} is not available or expired` );
        });

        return errors.length > 0 ? errors : true;
            
    }

    now() {
        var tzoffset = (new Date()).getTimezoneOffset() * 60000; // offset in milliseconds
        return (new Date(Date.now() - tzoffset)).toISOString(); // => '2015-01-26T06:40:36.181'
    }
}