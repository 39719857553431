import Vue from 'vue';
import VueRouter from 'vue-router';
import { Auth } from 'aws-amplify';
import store from '@/store';
import adminRoutes from '@/router/admin'

Vue.use(VueRouter)

const routes = [{
		path		: '/',
		name		: 'Home',
		component	: () => import('@/views/Home.vue'),
		meta		: { title: 'Inicio', showTopNav: true, showBottomNav: true }
	},{
		path		: '/contactanos',
		name		: 'ContactUs',
		component	: () => import('@/views/ContactUs.vue'),
		meta		: { title: 'Contáctanos', showTopNav: true, showBottomNav: true }
	}, {
		path		: '/login',
		name		: 'Login',
		component	: () => import('@/views/Login.vue'),
		meta		: { title: 'Login', showTopNav: false, showBottomNav: false }
	}, {
		path		: '/signin',
		name		: 'Sign In',
		component	: () => import('@/views/SignIn.vue'),
		meta		: { title: 'Sign In', showTopNav: false, showBottomNav: false }
	}, {
		path		: '/reglas',
		name		: 'Rules',
		component	: () => import('@/views/Rules.vue'),
		meta		: { title: 'Reglas', showTopNav: true, showBottomNav: true }
	}, {
		path		: '/producto/:slug',
		name		: 'Product',
		props		: true,
		component	: () => import('@/views/Product.vue'),
		meta		: { title: 'Producto', showTopNav: true, showBottomNav: true }
	}, {
		path		: '/productos',
		name		: 'ProductList',
		component	: () => import('@/views/ProductList.vue'),
		meta		: { title: 'Productos', showTopNav: true, showBottomNav: true }
	}, {
		path		: '/productos/:type',
		name		: 'ProductListByType',
		props		: true,		
		component	: () => import('@/views/ProductList.vue'),
		meta		: { title: 'Productos', showTopNav: true, showBottomNav: true }
	}, {
		path		: '/suscripciones',
		name		: 'Membership',
		component	: () => import('@/views/customers/Subscriptions.vue'),
		meta		: { title: 'Suscripciones', showTopNav: true, showBottomNav: true }
	}, {
		path		: '/checkins',
		name		: 'CheckIns',
		component	: () => import('@/views/customers/CheckIns.vue'),
		meta		: { title: 'Check-ins', showTopNav: true, showBottomNav: true }
	}, {
		path		: '/ordenes',
		name		: 'Orders',
		component	: () => import('@/views/customers/Orders.vue'),
		meta		: { title: 'Órdenes', showTopNav: true, showBottomNav: true }
	}, {
		path		: '/ordenes/:orderId',
		name		: 'Order',
		props		: true,
		component	: () => import('@/views/customers/Order.vue'),
		meta		: { title: 'Órden', showTopNav: true, showBottomNav: true }
	}, {
		path		: '/checkout',
		name		: 'Checkout',
		component	: () => import('@/views/Checkout.vue'),
		meta		: { title: 'Checkout', requiresAuth: true, showTopNav: true, showBottomNav: false }
	}, {
		path		: '/thankyou/:orderId',
		name		: 'ThankYou',
		props		: true,
		component	: () => import('@/views/ThankYou.vue'),
		meta		: { title: 'Gracias por su compra', requiresAuth: true, showTopNav: false, showBottomNav: false }
	}, {
		path		: '/perfil',
		name		: 'Profile',
		component	: () => import('@/views/customers/Profile.vue'),
		meta		: { title: 'Perfil', requiresAuth: true, showTopNav: true, showBottomNav: true },
		props: true,
	}, {
		path		: '/evidencias',
		name		: 'Evidencias',
		component	: () => import('@/views/customers/Resources.vue'),
		meta		: { title: 'Evidencias', requiresAuth: true, showTopNav: true, showBottomNav: true },
		props: true,
	},  {
		path		: '/progreso',
		name		: 'Progreso',
		component	: () => import('@/views/customers/Progress.vue'),
		meta		: { title: 'Progreso', requiresAuth: true, showTopNav: true, showBottomNav: true },
		props: true,
	}, {
		path		: '/perfil/editar',
		name		: 'Edit Profile',
		component	: () => import('@/views/customers/EditProfile.vue'),
		meta		: { title: 'Editar Perfil', requiresAuth: true, showTopNav: true, showBottomNav: true },
	}, {
		path		: '/checkin/:sessionId',
		name		: 'CheckIn',
		props		: true,
		component	: () => import('@/views/CheckIn.vue'),
		meta		: { title: 'Check-In', requiresAuth: true, showTopNav: true, showBottomNav: true },
	},
	{
		path		: '/subscription/auto-renew/:subscriptionId',
		name		: 'Auto-renew Subscription',
		props		: true,
		component	: () => import('@/views/SubscriptionAutoRenew.vue'),
		meta		: { title: 'Auto-renew Subscription', requiresAuth: false, showTopNav: false, showBottomNav: false },
	},
	{
		path		: '/politicas',
		name		: 'Políticas',
		props		: false,
		meta		: { title: 'Políticas', requiresAuth: false, showTopNav: true, showBottomNav: true },
		component	: () => import('@/views/Politicas.vue'),
	},

	/*************
	 * ADMIN AREA
	 *************/ 
	...adminRoutes,
	{
		path		: '*',
		name		: '404',
		component	: () => import('@/views/NotFound.vue'),
		meta		: { title: 'No se ha encontrado (404)', showTopNav: true, showBottomNav: true },
	},
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})

router.beforeEach(async (to, from, next) => {
	const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
	const isAdmin = to.matched.some(record => record.meta.isAdmin)
	const isPetuPunchAdmin = to.matched.some(record => record.meta.isPetuPunchAdmin)
	const isAuthenticated = await Auth.currentUserInfo(); // !!(JSON.parse(localStorage.getItem('petu-user')) || {});
	const isCustomerAdmin = await store.getters['auth/isAdmin'];
	const isCustomerPetuPunchAdmin = await store.getters['auth/isPetuPunchAdmin'];

	if(requiresAuth && !isAuthenticated) {
		localStorage.setItem('returnUrl', to.fullPath);

		if(isAdmin || isPetuPunchAdmin) next('/secure');
		else		next('/login');
	} else {
		if((isAdmin && isCustomerAdmin !== true) || (isPetuPunchAdmin && isCustomerPetuPunchAdmin !== true)) // isAuthenticated.attributes["custom:is_admin"] != "1") 
			next('/')
		else
			next();
	}
})

router.afterEach((to) => {
	Vue.nextTick(() => { document.title = to.meta.title ? to.meta.title : 'Petu Power' });
})

export default router
