<template>
    <v-app-bar app flat color="transparent" hide-on-scroll>
        <img src="@/assets/logo.png" width="60" class="mt-3" @click="$router.push('/')" style="cursor: pointer">
        <v-spacer></v-spacer>

        <v-badge :content="cartQty" class="mt-3 mr-4"  color="accent" bordered overlap offset-x="25" offset-y="20" v-if="cartQty > 0 && $route.path != '/checkout'">
            <v-btn icon @click="showCart = !showCart"><v-icon>mdi-shopping-outline</v-icon></v-btn>
        </v-badge>
        <v-menu v-if="isLoggedIn" bottom min-width="200px" content-class="rounded-xl">
            <template v-slot:activator="{ on }">
                <v-btn class="mt-2" icon x-large v-on="on">
                    <v-avatar class="white--text petu-dark-green-bg" size="45">
                        <h4 v-if="!profilePic">{{ initials }}</h4>
                        <v-img v-else :src="profilePic"></v-img>
                    </v-avatar>
                </v-btn>
            </template>
            <v-card >
                <v-list-item-content class="justify-center pb-0">
                    <div class="mx-auto text-center">
                        <v-avatar class="white--text mb-2 petu-dark-green-bg">
                            <h3 v-if="!profilePic">{{ initials }}</h3>
                            <v-img v-else :src="profilePic"></v-img>
                        </v-avatar>
                        <h4>{{ customer.firstName }}</h4>
                        <p class="text-caption">{{ customer.email }}</p>
                        <v-divider></v-divider>
                            <v-btn to="/perfil" class="no-uppercase" tile block depressed text>Perfil</v-btn>
                        <v-divider></v-divider>
                            <v-btn to="/suscripciones" class="no-uppercase" tile block depressed text>Suscripciones</v-btn>
                        <v-divider></v-divider>
                            <v-btn to="/ordenes" class="no-uppercase" tile block depressed text>Órdenes</v-btn>
                        <v-divider></v-divider>
                            <v-btn to="/checkins" class="no-uppercase" tile block depressed text>Check-ins</v-btn>
                        <v-divider></v-divider>
                            <v-btn to="/evidencias" class="no-uppercase" tile block depressed text>Evidencias</v-btn>
                        <v-divider></v-divider>
                            <v-btn to="/progreso" class="no-uppercase" tile block depressed text>Progreso</v-btn>
                        <v-divider v-if="customer.isAdmin"></v-divider>
                            <v-btn to="/secure/dashboard" class="no-uppercase" tile block depressed text v-if="customer.isAdmin">Admin</v-btn>
                        <v-divider v-if="customer.isPetuPunchAdmin"></v-divider>
                            <v-btn v-if="customer.isPetuPunchAdmin" to="/secure/petu-punch" class="no-uppercase" tile block depressed text>Petu Punch</v-btn>
                        <v-divider></v-divider>
                            <v-btn @click="logout" class="no-uppercase" tile block depressed text>Salir</v-btn>
                    </div>
                </v-list-item-content>
            </v-card>
        </v-menu>

        <v-btn v-else icon to="/login" class="mt-2"><v-icon x-large>mdi-account-circle</v-icon></v-btn>

        <cart v-model="showCart"></cart>
    </v-app-bar>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { PetuCustomer } from '@/classes/customer';
import Cart from '@/components/Cart';

export default {
    data:() => ({ showCart: false }),
    mounted() {
        this.$root.$on('show-cart', () => this.showCart = true )
    },
    beforeDestroy(){
        this.$root.$off('show-cart')
    },
    methods: {
        ...mapActions({ logout: 'auth/logout' }),
    },
    computed: {
        ...mapGetters({ 
            customer    : 'auth/customer',
            profilePic  : 'auth/profilePic', 
            isLoggedIn  : 'auth/isLoggedIn',
            cartQty     : 'cartInfo/cartQty' 
        }),
        initials() { return this.customer ? (new PetuCustomer(this.customer)).getInitials() : '' },
    },
    components: { Cart }
}
</script>

<style scoped>
header.v-toolbar {
    --transition: .3s ease-in-out;

    transition: background-color var(--transition),
                background-image var(--transition),
                backdrop-filter var(--transition),
                opacity var(--transition);
}
header.v-app-bar--is-scrolled {
    background: rgba(255,255,255,.7) !important;
    backdrop-filter: blur(15px);
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    box-shadow: 0 0 10px 0 rgba(0,0,0,.15);
}
header.v-toolbar > .v-toolbar__content {
    transition: min-height var(--transition);
}
header.v-toolbar.v-app-bar--is-scrolled > .v-toolbar__content {
    min-height: calc(var(--header-height) * var(--shrink-me))!important;
    height: calc(var(--header-height) * var(--shrink-me));
}
header.v-toolbar .elementor-nav-menu .elementor-item {
    transition: padding var(--transition);
}
header.v-toolbar > .elementor-container .logo img {
    transition: max-width var(--transition);
}
header.v-toolbar.elementor-sticky--effects .logo img {
    max-width: calc(100% * var(--shrink-me));
}
</style>