export default [{
    path: '/secure',
    name: 'admin',
    component: () => import('@/views/admin/Login.vue'),
    title: 'Admin Login',
}, {
    path: '/secure/dashboard',
    name: 'Admin Dashboard',
    component: () => import('@/views/admin/Dashboard.vue'),
    meta: { requiresAuth: true, isAdmin: true },
}, {
    path: '/secure/products',
    name: 'Admin - Product List',
    component: () => import('@/views/admin/ProductList.vue'),
    meta: { requiresAuth: true, isAdmin: true },
}, {
    path: '/secure/product',
    name: 'Admin - Create Product',
    props: true,
    component: () => import('@/views/admin/Product.vue'),
    meta: { requiresAuth: true, isAdmin: true },
}, {
    path: '/secure/product/:productId',
    name: 'Admin - Product',
    props: true,
    component: () => import('@/views/admin/Product.vue'),
    meta: { requiresAuth: true, isAdmin: true },
}, {
    path: '/secure/customers',
    name: 'Admin - Customer List',
    component: () => import('@/views/admin/CustomerList.vue'),
    meta: { requiresAuth: true, isAdmin: true },
}, {
    path: '/secure/customer',
    name: 'Admin - Create Customer',
    props: true,
    component: () => import('@/views/admin/Customer.vue'),
    meta: { requiresAuth: true, isAdmin: true },
}, {
    path: '/secure/customer/:customerId',
    name: 'Admin - Customer',
    props: true,
    component: () => import('@/views/admin/Customer.vue'),
    meta: { requiresAuth: true, isAdmin: true },
}, {
    path: '/secure/customer/profile/:customerId',
    name: 'Admin - Customer Profile',
    props: true,
    component: () => import('@/views/admin/CustomerProfile.vue'),
    meta: { requiresAuth: true, isAdmin: true },
}, {
    path: '/secure/orders',
    name: 'Admin - Order List',
    component: () => import('@/views/admin/OrderList.vue'),
    meta: { requiresAuth: true, isAdmin: true },
}, {
    path: '/secure/order/:orderId',
    name: 'Admin - Order',
    props: true,
    component: () => import('@/views/admin/Order.vue'),
    meta: { title: 'Admin - Orden', requiresAuth: true, isAdmin: true },
}, {
    path: '/secure/new-order',
    name: 'Admin - Create Order',
    props: false,
    component: () => import('@/views/admin/NewOrder.vue'),
    meta: { title: 'Admin - Crear Orden', requiresAuth: true, isAdmin: true },
},{
    path: '/secure/coupons',
    name: 'Admin - Coupon List',
    component: () => import('@/views/admin/CouponList.vue'),
    meta: { requiresAuth: true, isAdmin: true },
}, {
    path: '/secure/coupon',
    name: 'Admin - Create Coupon',
    props: true,
    component: () => import('@/views/admin/Coupon.vue'),
    meta: { requiresAuth: true, isAdmin: true },
}, {
    path: '/secure/coupon/:couponId',
    name: 'Admin - Coupon',
    props: true,
    component: () => import('@/views/admin/Coupon.vue'),
    meta: { requiresAuth: true, isAdmin: true },
}, {
    path: '/secure/session',
    name: 'Admin - New Session',
    component: () => import('@/views/admin/SessionNew.vue'),
    meta: { requiresAuth: true, isAdmin: true },
}, {
    path: '/secure/sessions',
    name: 'Admin - Session List',
    component: () => import('@/views/admin/SessionList.vue'),
    meta: { requiresAuth: true, isAdmin: true },
}, {
    path: '/secure/session/:sessionId',
    name: 'Admin Check-In',
    props: true,
    component: () => import('@/views/admin/Session.vue'),
    meta: { requiresAuth: true, isAdmin: true },
}, {
    path: '/secure/profile',
    name: 'Admin - My Profile',
    props: true,
    component: () => import('@/views/admin/User.vue'),
    meta: { requiresAuth: true, isAdmin: true },
}, {
    path: '/secure/messages',
    name: 'Admin - Messages',
    props: true,
    component: () => import('@/views/admin/ContactFormList.vue'),
    meta: { title: 'Admin - Messages', requiresAuth: true, isAdmin: true },
}, {
    path: '/secure/messages/:id',
    name: 'Admin - Message',
    props: true,
    component: () => import('@/views/admin/ContactForm.vue'),
    meta: { title: 'Mensajes', requiresAuth: true, isAdmin: true },
}, {
    path: '/secure/evidence',
    name: 'Admin - Evidence',
    component: () => import('@/views/admin/EvidenceList.vue'),
    meta: { title: 'Evidencias', requiresAuth: true, isAdmin: true },
}, {
    path: '/secure/marron',
    name: 'Admin - Marron',
    component: () => import('@/views/admin/Marron.vue'),
    meta: { title: 'Marron', requiresAuth: true, isAdmin: true },
}, {
    path: '/secure/timestamps',
    name: 'Admin - Timestamps',
    component: () => import('@/views/admin/Timestamps.vue'),
    meta: { title: 'Ponches', requiresAuth: true, isPetuPunchAdmin: true },
}, 	{
    path: '/secure/petu-punch',
    name: 'Petu Punch',
    component: () => import('@/views/admin/PetuPunch.vue'),
    meta		: { title: 'Petu Punch', showTopNav: false, showBottomNav: false, requiresAuth: true, isPetuPunchAdmin: true }
}, {
    path: '/secure/ath-movil',
    name: 'Crear Órdenes ATH Móvil',
    component: () => import('@/views/admin/ATHMovilCreateOrder.vue'),
    meta		: { title: 'Crear Órdenes ATH Móvil', showTopNav: false, showBottomNav: false, requiresAuth: true }
}, {
    path: '/secure/reporting',
    name: 'Reporting',
    component: () => import('@/views/admin/Reporting.vue'),
    meta		: { title: 'Reporting', showTopNav: false, showBottomNav: false, requiresAuth: true }
}]