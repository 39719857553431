// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';

const ProductType = {
  "SIMPLE": "Simple",
  "VIRTUAL": "Virtual"
};

const State = {
  "DRAFT": "Draft",
  "PUBLISHED": "Published"
};

const TrainingType = {
  "NA": "NA",
  "PRESENCIAL": "Presencial",
  "ONLINE": "Online",
  "ONE_ON_ONE": "OneOnOne"
};

const SubscriptionFrequency = {
  "WEEKLY": "Weekly",
  "MONTHLY": "Monthly",
  "ANNUALLY": "Annually"
};

const ResourceType = {
  "VIDEO": "Video",
  "IMAGE": "Image"
};

const AvailableOptions = {
  "WEEK_DAYS": "WeekDays",
  "WEEKENDS": "Weekends",
  "MONDAY": "Monday",
  "TUESDAY": "Tuesday",
  "WEDNESDAY": "Wednesday",
  "THURSDAY": "Thursday",
  "FRIDAY": "Friday",
  "SATURDAY": "Saturday",
  "SUNDAY": "Sunday"
};

const Gender = {
  "MALE": "Male",
  "FEMALE": "Female",
  "NA": "NA"
};

const OrderStatus = {
  "PROCESSING": "Processing",
  "COMPLETED": "Completed",
  "CANCELLED": "Cancelled",
  "PENDING_PAYMENT": "PendingPayment",
  "SHIPPED": "Shipped",
  "FAILED": "Failed",
  "DRAFT": "Draft",
  "REFUNDED": "Refunded"
};

const SessionType = {
  "PRESENCIAL": "Presencial",
  "ONE_ON_ONE": "OneOnOne"
};

const CouponType = {
  "AMOUNT": "Amount",
  "PERCENTAGE": "Percentage"
};

const { Product, CustomerSubscription, Customer, CheckIn, CustomerResource, Cart, Order, Session, Coupon, ContactForm, ErrorLog, Timestamp, Dimensions, Image, S3Resource, Resource, Address, StripeInfo, StripeCard, CartProduct, OrderProduct, Note, CouponRules } = initSchema(schema);

export {
  Product,
  CustomerSubscription,
  Customer,
  CheckIn,
  CustomerResource,
  Cart,
  Order,
  Session,
  Coupon,
  ContactForm,
  ErrorLog,
  Timestamp,
  ProductType,
  State,
  TrainingType,
  SubscriptionFrequency,
  ResourceType,
  AvailableOptions,
  Gender,
  OrderStatus,
  SessionType,
  CouponType,
  Dimensions,
  Image,
  S3Resource,
  Resource,
  Address,
  StripeInfo,
  StripeCard,
  CartProduct,
  OrderProduct,
  Note,
  CouponRules
};