/*
 * Author: Softech
 * Company: Softech Corporation
 * Version: 1.0.0
 * Date: 2021-05-10
 * 
 * Description: 
 * 
 */

import { _st } from '@/softech';
import { API } from '@/inc/api';
// import { getCustomerResource, resourceByCustomer } from '@/graphql/queries';
// import { API, graphqlOperation } from 'aws-amplify';

export class CustomerResource {
    constructor(customerResource = null) {
        if (_st.isNU(customerResource)) {
            this.data = { id: null, name: null, message: null, dateCreated: null };
        } else {
            this.data = customerResource;
        }
    }

    // async load(customerResourceId = null) {
    //     if(_st.isEmpty(customerResourceId)) { return }

    //     const cf    = await API.graphql(graphqlOperation(getCustomerResource, { customerID: customerResourceId }))
    //     this.data   = cf.data.getContactForm
    // }

    // async getList() {
    //     const crs = await API.graphql(graphqlOperation(listCustomerResources, { limit: 500 }))
    //     return crs.data.listCustomerResources.items;
    // }

    async getList( limit = null ) {
        try {
            let api = new API();

            let args = '';
            if( !_st.isNUE( limit ) ) {
                args = `/${limit}`;
            }

            let res = await api.get(`/customer/resources${args}`);

            if( res.data.status !== true ) {
                return Promise.reject( res );
            }

            return res.data.data;
        } 
        catch(error) {
            return Promise.reject( error );
        }
    }

    // async getListByCustomer( customerId ) {
    //     const crs = await API.graphql(graphqlOperation(resourceByCustomer, { customerID: customerId }))
    //     return crs.data.resourceByCustomer.items;
    // }
}