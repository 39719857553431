<template>
    <div class="bottom-nav">

        <v-row class="ma-0 pa-0">
            <v-col cols="4" class="ma-0 pa-0 text-center">
                <v-btn depressed  text x-large tile active-class="no-active" to="/productos" style="z-index:0;">
                    <v-icon size="32" class="font-weight-thin" color="#ee3a8c">mdi-cart-outline</v-icon>
                </v-btn>
            </v-col>
            <v-col cols="4" class="ma-0 pa-0 text-center">
                <v-btn large fab dark to="/suscripciones" style="margin-top:-25px;z-index:3;" color="#ee3a8c" active-class="no-active" class="main-button">
                    <img src="@/assets/Membership-icon-01.svg" width="38">
                </v-btn>
            </v-col>
            <v-col cols="4" class="ma-0 pa-0 text-center">
                <v-btn depressed text x-large tile active-class="no-active" to="/contactanos" style="z-index:0;">
                    <v-icon size="32" color="#ee3a8c">mdi-comment-text-outline</v-icon>
                </v-btn> 
            </v-col>
        </v-row>
    </div>
</template>

<script>
    export default {
        methods:{
            goto() {

            }
        }
    }
</script>

<style scoped>
.bottom-nav {
    position: fixed;
    bottom: 0;
    height: 50px;
    width: 100%;
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
    padding: 0 10px;
    display: flex;
    justify-content: space-between;
}
.main-button {
    border: 3px solid #fff !important;
}
.v-btn--active.no-active::before {
  opacity: 0 !important;
}
</style>

<style>
.petu-content {
    margin-bottom: 60px;
}
</style>