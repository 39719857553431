/*
 * Author: Roberto Torres
 * Company: Softech Corporation
 * Version: 1.0.0
 * Date: 2021-05-10
 * 
 * Description: 
 * Class to facilitate the creation and manipulation of 
 * products in the Petu App
 * 
 */

import { API } from '@/inc/api';
import { _st } from '@/softech';
import store from '@/store';
// import { getProductByName } from '@/graphql/customQueries';

import { Storage } from 'aws-amplify';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import awsconfig from "@/aws-exports";

import slugify from '@sindresorhus/slugify';

export class PetuProduct {
    constructor(product = null) {
        if(_st.isNU(product))
            this.data = {
                id                      : '',
                name                    : '',
                shortDescription        : '',
                description             : '',
                categories              : [],
                tags                    : [],
                price                   : 0,
                salePrice               : 0,
                saleFrom                : null,
                saleTo                  : null,
                manageStock             : false,
                stock                   : 0,
                slug                    : '',
                type                    : 'simple',
                state                   : 'draft',
                generalRules            : '',
                cancelationRules        : '',
                strikesRules            : '',
                trainingType            : 'presencial',
                generateSubscription    : false,
                subscriptionFrequency   : 'monthly',
                subscriptionSessions    : 0,
                isTaxable               : true,
                length                  : 0,
                width                   : 0,
                height                  : 0,
                weight                  : 0,
                images                  : [],
                resources               : [],
                dateCreated             : null,
                lastUpdate              : null,
            };
        else
            this.data = product;

        // helpers
        this.srcImages      = [];   // array to manipulate new images that are not in the S3 bucket
        this.srcResources   = [];   // array to manipulate product resources
    }

    async load(productId = null) {
        if( _st.isNUE( productId ) ) {
            console.error('Product ID cannot be empty.');
            return;
        }

        // load product from DB
        // const p = await API.graphql({ query: getProduct, variables: { id: productId }, authMode: 'API_KEY' });
        try {
            let api = new API();

            let res = await api.get(`/product/${btoa(productId)}`);

            if( res.data.status !== true ) {
                return Promise.reject( res );
            }

            this.data = res.data.data;
        }
        catch( error ) {
            return Promise.reject( error );
        }
        
        // this.data = p.data.getProduct;
    }
    async loadFrugal(productId = null) {
        if(_st.isEmpty(productId)) {
            console.error('Product ID cannot be empty.');
            return;
        }

        // load product from DB
        // const p = await API.graphql(graphqlOperation(getProduct, { id: productId }));
        const p = await API.graphql({ query: getProductFrugal, variables: { id: productId }, authMode: 'API_KEY' });
        
        this.data = p.data.getProductFrugal;
    }
    async loadBySlug( slug = null ) {
        if( _st.isEmpty( slug ) ) {
            console.error('Slug cannot be empty.');
            return Promise.reject('Slug cannot be empty.');
        }

        try {
            let api = new API();

            let res = await api.get(`/product/slug/${slug}`);

            if( res.data.status !== true ) {
                return Promise.reject( res );
            }

            this.data = res.data.data;
        }
        catch( error ) {
            return Promise.reject( error );
        }

        // this.data = p.data.productBySlug.items[0];
    }

    async loadByName( name = null ) {
        if(_st.isEmpty(name)) {
            return Promise.reject('Name cannot be empty.');
        }

        try {
            let api = new API();

            let res = await api.get(`/product/slug/${slugify( name )}`);

            if( res.data.status !== true ) {
                return Promise.reject( res );
            }

            this.data = res.data.data;
        }
        catch( error ) {
            return Promise.reject( error );
        }

    }

    async getList( ids = '' ) {
        // const products = await API.graphql(graphqlOperation(listProducts));
        // return products.data.listProducts.items;

        try {
            // let variables = {};
            // if( !_st.isNUE( filter ) ) variables.filter = filter;
            // if( !_st.isNUE( limit ) ) variables.limit = limit;

            // let products = null;
            // if( _st.isNUE( variables ) )
            //     products = await API.graphql({ query: listProducts, variables: {}, authMode: 'API_KEY' });
            // else
            //     products = await API.graphql({ query: listProducts, variables, authMode: 'API_KEY' });

            // return products.data.listProducts.items;

            let api = new API();
            let res = await api.get('/products' + ( ids == '' ? '' : `/${ids}`));

            if( res.data.status !== true )
                return [];

            return res.data.data;
        }
        catch(error) {
            console.log(error);
            return Promise.reject(error);
        }
    }
    async getPurchasableList( type ) {

        var products = [];
        let api = new API();
        let res = await api.get('/products/active');

        if( res.data.status !== true )
            return products;

        products = res.data.data;
        // var products = await API.graphql({ query: productByStateTrainingType, variables: { state: State.PUBLISHED }, authMode: 'API_KEY' })

        if( type ) {
            products = products.filter(p => p.trainingType.toLowerCase() == type.toLowerCase());
        }

        let purchasable = []
        products.forEach((p) => {
            // if(p.state != State.PUBLISHED) return;
            if( p.type == 'virtual' ) {
                purchasable.push(p)
            } 
            else if( !this.data.manageStock ) {
                purchasable.push(p)
            } 
            else if( p.stock >= 1 ) {
                purchasable.push(p)
            }
        })

        return purchasable;
    }

    async loadResources() {
        try {
            let api = new API();

            let res = await api.get(`/product/resources/${btoa(this.data.id)}`);

            if( res.data.status !== true ) {
                return Promise.reject( res );
            }

            this.data.resources = res.data.data;
            for( let rsrc of this.data.resources ) {
                rsrc.availableOn = rsrc.availableOn.split(',');
            }
        }
        catch( error ) {
            return Promise.reject( error );
        }
    }

    async publish() {
        try {
            if( this.data.state == 'published' ) {
                console.log('Product already published');
                return;
            }

            // save the new status into the DB
            this.data.state = 'published';
            await this.save();
        }
        catch(error) {
            console.log(error);
        }
    }

    async unpublish() {
        try {
            if( this.data.state == 'draft' ) {
                console.log('Product is not published');
                return;
            }

            // save the new status into the DB
            this.data.state = 'draft';
            await this.save();
        }
        catch(error) {
            console.log(error);
        }
    }

    async save() {

        // delete properties that are not allowed on the update
        delete this.data.subscriptions;
        delete this.data.createdAt;
        delete this.data.updatedAt;
        
        // create new product
        try {
            
            if( _st.isNUE( this.data.id ) ) {
                this.data.price = parseFloat(this.data.price);
                this.data.salePrice = parseFloat(this.data.salePrice);
                this.data.stock = parseInt(this.data.stock);
                this.data.id = uuidv4();

                // upload new images to the S3 bucket
                for(let img of this.srcImages) {
                    await this.createImage(img);
                }

                // save resource
                for(let rsc of this.srcResources) {
                    await this.saveResource(rsc);
                }
                
                // const p = await API.graphql(graphqlOperation(createProduct, { input: this.data }));
                // this.data = p.data.createProduct;
            }

            // update existing product
            else {
                this.data.price = parseFloat(this.data.price);
                this.data.salePrice = parseFloat(this.data.salePrice);
                this.data.stock = parseInt(this.data.stock);

                // remove delete images
                for( let i = this.data.images.length; i > 0; i-- ) {
                // for( let img of this.data.images ) {
                    let img = this.data.images[i - 1];
                    let found = this.srcImages.find(i => {
                        return img.src.key.includes(i.name);
                    });

                    if( _st.isNU( found ) ) 
                        await this.deleteImage( img );
                }

                // upload new images to the S3 bucket
                for( let img of this.srcImages ) {
                    // update primary property
                    let prodImg = this.data.images.find(i => {
                        return i.src.key.includes(img.name);
                    });
                    
                    if( !_st.isNU( prodImg ) ) 
                        prodImg.primary = img.primary;

                    // go to the next item if it is not a new image
                    if( !img.isNew ) 
                        continue;

                    // create new images
                    await this.createImage( img );
                }

                for( let i = this.data.resources.length; i > 0; i-- ) {
                // for( let img of this.data.images ) {
                    let rsrc = this.data.resources[i - 1];
                    let found = this.srcResources.find(i => {
                        return rsrc.src.key.includes( i.src.key );
                    });

                    if( _st.isNU( found ) ) {
                        await Storage.remove(rsrc.src.key, { level: "protected" });   
                    }
                }

                // save resource
                // clean the resource object before updating it
                this.data.resources = [];
                for( let rsc of this.srcResources ) {
                    await this.saveResource( rsc );
                }

                // await API.graphql(graphqlOperation(updateProduct, { input: this.data }));
            }

            // save to DB
            let api = new API();
            let res = await api.post('/product', this.data);

            if( res.data.status !== true ) {
                return Promise.reject( res );
            }

            this.data = res.data.data;

            for( let rsrc of this.data.resources ) {
                rsrc.availableOn = rsrc.availableOn.split(',');
            }

            // clear the helper variable
            this.srcImages = [];

            return Promise.resolve("Success");
        }
        catch(error) {
            console.log("Error saving product", error);
            return Promise.reject(error);
        }
    }

    async getImagesSrc( size = 'original' ) {
        // get images src from S3 bucket
        try {
            this.srcImages = [];
            for( let img of this.data.images ) {
                // const imgUrl = await Storage.get(img.src.key);
                
                let src = img.src;
                if(size == 'small' && !_st.isNU(img.smallThumbnail))
                    src = img.smallThumbnail;
                if(size == 'medium' && !_st.isNU(img.mediumThumbnail))
                    src = img.mediumThumbnail;

                let imgUrl = `https://${src.bucket}.s3.amazonaws.com/public/${src.key}`;

                this.srcImages.push({
                    name    : img.src.key.substr(img.src.key.lastIndexOf("/") + 1),
                    size    : '',
                    imgUrl  : imgUrl,
                    data    : null,
                    primary : img.primary,
                    order   : 0,
                    isNew   : false,
                });
            }

            return Promise.resolve("Success");
        }
        catch(error) {
            console.log("Error getting product images", error);
            return Promise.reject(error);
        }
    }
    async getResourcesSrc() {
        // get resources src from S3 bucket
        try {
            this.srcResources = [];
            for(let rsc of this.data.resources) {
                const rscUrl = await Storage.get(rsc.src.key, {
                    level: "protected",
                    expires: 180,
                    identityId: rsc.src.identityId
                });

                this.srcResources.push({
                    id              : rsc.id,
                    name            : rsc.name,
                    type            : rsc.type,
                    src             : rsc.src,
                    description     : rsc.description,
                    instructions    : rsc.instructions,
                    onlyOn          : rsc.onlyOn,
                    availableOn     : rsc.availableOn,
                    resource        : null,
                    isNew           : false,
                    isEdit          : false,
                    rscUrl          : rscUrl
                });
            }

            return Promise.resolve("Success");
        }
        catch(error) {
            console.log("Error getting product resources", error);
            return Promise.reject(error);
        }
    }
    async createImage( img ) {
        const {
            "aws_user_files_s3_bucket": bucket,
            "aws_user_files_s3_bucket_region": region
        } = awsconfig;
        const { type: mimeType } = img.data;
        const extension = img.data.name.substr(img.data.name.lastIndexOf(".") + 1);
        const imgId = uuidv4();
        const key = `productImages/${imgId}.${extension}`;
        
        // S3 bucket storage create product image
        try {
            await Storage.put(key, img.data, {
                level: "public",
                contentType: mimeType,
                metadata: { productId: this.data.id.toString(), imageId: imgId }
            });

            this.data.images.push({
                id: 0,
                productId: this.data.id,
                src: {
                    bucket,
                    key,
                    region
                },
                smallThumbnail: {
                    bucket,
                    key: `productImages/${imgId}-small.jpg`,
                    region
                },
                mediumThumbnail: {
                    bucket,
                    key: `productImages/${imgId}-medium.jpg`,
                    region
                },
                primary: img.primary
            });

            console.log("image created");
            return Promise.resolve("Success");
        }
        catch(error) {
            console.log("Error creating product image", error);
            return Promise.reject(error);
        }
    }
    async deleteImage(img) {
        
        // S3 bucket storage create product image
        try {
            await Storage.remove(img.src.key, { level: "public" });

            // remove small thumbnail
            if(img.smallThumbnail?.key)
                await Storage.remove(img.smallThumbnail.key, { level: "public" });

            // remove medium thumbnail
            if(img.mediumThumbnail?.key)
                await Storage.remove(img.mediumThumbnail.key, { level: "public" });

            let ix = this.data.images.findIndex(i => {
                return i.src.key == img.src.key;
            });

            if( !_st.isNU(ix) ) 
                this.data.images.splice(ix, 1);

            console.log("image deleted");
            return Promise.resolve("Success");
        }
        catch(error) {
            console.log("Error deleting product image", error);
            return Promise.reject(error);
        }
    }
    async saveResource(rsc) {
        const {
            "aws_user_files_s3_bucket": bucket,
            "aws_user_files_s3_bucket_region": region
        } = awsconfig;

        console.log("saving resource");

        try {
            // check if it is a new resource
            if( _st.isNUE( rsc.id ) ) {

                const { type: mimeType } = rsc.resource;
                const extension = rsc.resource.name.substr(rsc.resource.name.lastIndexOf(".") + 1);
                const rscId = uuidv4();
                const key = `productResources/${rscId}.${extension}`;

                await Storage.put(key, rsc.resource, {
                    level: "protected",
                    contentType: mimeType,
                    metadata: { productId: this.data.id.toString() }
                });

                let user = store.getters['auth/user'];
                if(_st.isNU(user))
                    user = { id: '' };

                this.data.resources.push({
                    id: 0,
                    name: rsc.name,
                    type: rsc.type,
                    src: { bucket, key: key, region, identityId: user.id },
                    description: rsc.description,
                    instructions: rsc.instructions,
                    onlyOn: rsc.onlyOn,
                    availableOn: rsc.availableOn
                });
            }

            // update existing resource
            else {
                let srcKey = rsc.src.key;

                // check if the resource changed
                if(!_st.isNU(rsc.resource)) {

                    // remove old resource
                    await Storage.remove(rsc.src.key, { level: 'protected' });

                    // add new resource
                    const { type: mimeType } = rsc.resource;
                    const extension = rsc.resource.name.substr(rsc.resource.name.lastIndexOf(".") + 1);
                    const rscId = uuidv4();
                    const key = `productResources/${rscId}.${extension}`;
                    
                    await Storage.put(key, rsc.resource, {
                        level: "protected",
                        contentType: mimeType,
                        metadata: { productId: this.data.id }
                    });

                    srcKey = key;
                }

                // add product resource object with new information
                this.data.resources.push({
                    id              : rsc.id,
                    name            : rsc.name,
                    type            : rsc.type,
                    src             : !_st.isNU(rsc.resource) ? { bucket, key: srcKey, region } : rsc.src,
                    description     : rsc.description,
                    instructions    : rsc.instructions,
                    onlyOn          : rsc.onlyOn,
                    availableOn     : rsc.availableOn
                });
                // let prodResource = this.data.resources.find(r => {
                //     return r.id == rsc.id;
                // });

                // if(!prodResource) {
                //     console.log("Error saving product resource: resource trying to update doesn't exist");
                //     return;
                // }

                // prodResource.name           = rsc.name;
                // prodResource.type           = rsc.type;
                // prodResource.src            = _st.isNU(rsc.resource) ? { bucket, key: srcKey, region } : rsc.src;
                // prodResource.description    = rsc.description;
                // prodResource.instructions   = rsc.instructions;
                // prodResource.onlyOn         = rsc.onlyOn;
                // prodResource.availableOn    = rsc.availableOn;
            }

            console.log("resource saved");
            return Promise.resolve("Success");
        }
        catch(error) {
            console.log("Error saving product resource", error);
            return Promise.reject(error);
        }
    }
    isInStock(qty = 1) {
        if(this.data.type == 'virtual') return true;

        return !this.data.manageStock ? true : this.data.stock >= qty;
    }
    async getProductImage(size = 'original') {
        try {
            if( _st.isNUE( this.srcImages ) )
                this.getImagesSrc(size);

            if( this.srcImages.length == 1 )
                return this.srcImages[0].imgUrl;

            let img = this.srcImages?.find(i => {
                return i.primary == true;
            });
            
            if( _st.isNU( img ) )
                return this.srcImages[0]?.imgUrl;

            return img?.imgUrl;
        }
        catch(error) {
            console.log(error);
        }
    }
    needShipping() {
        return this.data.type == 'virtual' ? false : true;
    }
    isOnSale() {
        if(this.data.salePrice == 0)
            return false;

        if(this.data.saleFrom == null && this.data.saleTo == null)
            return true;
        
        if(this.data.saleFrom != null && this.data.saleTo != null) {
            // check if today is between saleFrom and saleTo
            if(moment().isBetween(moment(this.data.saleFrom), moment(this.data.saleTo))) 
                return true;
        }
        else if(this.data.saleFrom != null) {
            // check if today is after saleFrom
            if(moment().isAfter(moment(this.data.saleFrom)))
                return true;
        }
        else {
            // check if today is before saleTo
            if(moment().isBefore(moment(this.data.saleTo)))
                return true;
        }

        return false;
    }
    getPrice() {
        return this.isOnSale() ? this.data.salePrice : this.data.price;
    }
    isPurchasable() {
        return this.data.state == 'published' && this.isInStock();
    }
}