import Vue from 'vue'
import Vuex from 'vuex'
import { auth } from './auth/auth'
import { productsInfo } from './objects/products'
import { customersInfo } from './objects/customers'
import { couponsInfo } from './objects/coupons'
import { ordersInfo } from './objects/orders'
import { checkInsInfo } from './objects/checkIns'
import { cartInfo } from './objects/cart'
import { contactForm } from './objects/contactForm'
import { customerResource } from './objects/customerResource'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    auth,
    productsInfo,
    customersInfo,
    couponsInfo,
    ordersInfo,
    checkInsInfo,
    cartInfo,
    contactForm,
    customerResource
  }
})
