<template>
	<div id="app">
	<v-app>
		<v-main>
			<top-nav v-if="showTopNav"></top-nav>
			<v-container v-cloak fill-height class="align-start" fluid :class="{ 'petu-content': showBottomNav }">
				<router-view :key="$route.path"></router-view>
			</v-container>
			<bottom-nav v-if="showBottomNav"></bottom-nav>
		</v-main>
		<alert></alert>
	</v-app>
	</div>
</template>

<script>
import TopNav from "@/components/TopNav"
import BottomNav from "@/components/BottomNav"
import Alert from "@/components/Alert"
import { mapActions } from "vuex";

export default {
	name: 'PetuPower',
	components: { TopNav, BottomNav, Alert },
	data: () => ({
		loaded: false,
	}),
	mounted() {
		this.authAction();
		this.reloadCustomer();
		this.loaded = true;
	},
	methods: {
		...mapActions({
			'authAction'	: 'auth/authAction',
			'reloadCustomer': 'auth/reloadCustomer'
		}),
	},
	computed: {
		showTopNav() { return this.$route.meta.showTopNav && this.loaded },
        showBottomNav() { return this.$route.meta.showBottomNav && this.loaded },
	}
}
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
