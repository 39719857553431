/*
 * Author: Softech
 * Company: Softech Corporation
 * Version: 2.0.0
 * Date: 2023-10-02
 * 
 * Description: 
 * 
 */

import { _st } from '@/softech';
import store from '@/store';
import { API } from '@/inc/api';

export class ContactForm {
    constructor( contactForm = null ) {
        if( _st.isNU( contactForm ) ) {
            this.data = { id: null, name: null, message: null, email: null, dateCreated: null };
        } 
        else {
            this.data = contactForm;
        }
    }

    async load( formId = null ) {
        if( _st.isNUE( formId ) ) { 
            return; 
        }

        // const cf    = await API.graphql(graphqlOperation(getContactForm, { id: contact_form_id }));
        let api = new API();
        let res = await api.get( `/contact-form/${formId}` );

        if( res.data.status !== true ) {
            return Promise.reject( res );
        }

        this.data = res.data.data;
    }

    async getList() {
        // const cfs = await API.graphql(graphqlOperation(listContactForms, { limit: 500 }));
        let api = new API();
        let res = await api.get( `/contact-form/list` );

        if( res.data.status !== true ) {
            return Promise.reject( res );
        }

        return res.data.data;
    }

    async save() {
        try {
            let api = new API();
            let res = await api.post( '/contact-form', this.data );
            // if( store.getters['auth/isLoggedIn'] === true )
            //     cf = await API.graphql(graphqlOperation(createContactForm, { input: this.data }));
            // else
            //     cf = await API.graphql( { query: createContactForm, variables: { input: this.data }, authMode: 'API_KEY' } );

            

            this.data = res.data.data;
            return Promise.resolve( 'Success' );
        } catch(error) {
            console.error( 'Error saving contact form - ', error );
            return Promise.reject( error );
        }
    }
}