/*
 * Author: Roberto Torres
 * Company: Softech Corporation
 * Version: 1.0.0
 * Date: 2021-06-21
 * 
 * Description: 
 * Class to process payments with Stripe
 * 
 */

import { _st } from '@/softech';
import { API } from '@/inc/api';

export class StripeGateway {
    constructor({ customerId , card }) {
        this.customer   = '';
        this.card       = '';

        if( !_st.isNUE( this.customer ) )   this.customer = customerId;
        if( !_st.isNUE( card ) )            this.card = card;
    }

    async createCustomer({ id, email, name, phone, cardToken }) {
        try {
            let api = new API();
            let res = await api.post('/customer/stripe', { id, email, name, phone, cardToken });

            if( res.data.status !== true && res.data.isError === true ) 
                throw this.getError( customer.error );
            
            return res.data.data;
        } catch(error) {
            console.error(`STRIPE ERROR: ${error}`);
            throw error;
        }
    }
    async createCard({ customerId, cardToken, stripeCustomerId }) {
        try {
            let api = new API();
            let res = await api.post('/customer/stripe/card', { customerId, cardToken, stripeCustomerId } );

            if( res.data.status !== true && res.data.isError === true ) 
                throw this.getError(card.error);

            return res.data.data;
        } catch(error) {
            console.error(`STRIPE ERROR: ${error}`)
            throw error
        }
    }
    async deleteCard({ customerId, cardId }) {
        try {
            let api = new API();
            let res = await api.post('/customer/stripe/card/delete', { customerId, cardId } );

            if( res.data.status !== true && res.data.isError === true ) 
                throw this.getError( card.error );

            return true;
        }
        catch( error ) {
            console.error( `STRIPE ERROR: ${error}` );
            throw error;
        }
    }
    async charge({ amount, description }) {
        try {
            // validate that the amount and description is set
            if( _st.isNUE( this.customer ) )    throw 'The customer is not set.';
            if( _st.isNUE( this.card ) )        throw 'The card is no set.';
            if( _st.isNUE( amount ) )           throw 'The charge amount must be a positive value greater than 0.';

            let api = new API();
            let res = await api.post('/stripe/charge', { 
                amount      : this.toStripeAmount( amount ),
                customerId  : this.customer,
                source      : this.card,
                description : description
            });

            if( res.data.status !== true && res.data.isError === true ) 
                throw this.getError( charge.error );

            if( !res.data.data.paid )
                throw `[${res.data.data.failure_code}] ${res.data.data.failure_message}`;

            return charge.data.data;
        }
        catch( error ) {
            console.error( `CHARGE ERROR: ${error}` );
            throw error;
            // Promise.reject( error );
        }
    }
    async refund({ chargeId }) {
        try {
            if( _st.isNUE( chargeId ) )
                throw 'The charge id is not set.';

            let api = new API();
            let res = await api.post('/stripe/refund', { chargeId });

            if( res.data.status !== true && res.data.isError === true ) 
                throw this.getError( refund.error );

            return res.data.data;
        }
        catch( error ) {
            console.error( `REFUND ERROR: ${error}` );
            throw error;
        }
    }
    toStripeAmount( val ) {
        var re = new RegExp('^-?\\d+(?:.\\d{0,2})?');
        return Math.floor(val.toString().match(re)[0] * 100);
    }
    getError( err ) {
        switch( err.type ) {
            case 'StripeCardError':
                // A declined card error
                return err.message; // => e.g. "Your card's expiration year is invalid."
            case 'StripeRateLimitError':
                // Too many requests made to the API too quickly
                return `[${err.code}] ${err.raw?.message}`;
            case 'StripeInvalidRequestError':
                // Invalid parameters were supplied to Stripe's API
                return `[${err.code}] ${err.raw?.message}`;
            case 'StripeAPIError':
                // An error occurred internally with Stripe's API
                return `[${err.code}] ${err.raw?.message}`;
            case 'StripeConnectionError':
                // Some kind of error occurred during the HTTPS communication
                return `[${err.code}] ${err.raw?.message}`;
            case 'StripeAuthenticationError':
                // You probably used an incorrect API key
                return `[${err.code}] ${err.raw?.message}`;
            case 'card_error':
                return `[${err.code}] ${err.message}`;
            default:
                // Handle any other types of unexpected errors
                return `[${err.code}] ${err.raw?.message}`;
          }
    }
}