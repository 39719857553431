import { PetuCart } from '@/classes/cart';
import { _st } from '@/softech';

export const cartInfo = {
    namespaced: true,
    state: {
        cart            : null,
        cartQty         : 0,
        cartSubtotal    : 0,
        cartTax         : 0,
        cartShipping    : 0,
        cartDiscounts   : 0,
        cartTotal       : 0,
        cartProducts    : [],
        cartCoupons     : [],
        cartNeedShipping: false,
    },
    mutations: {
        async setCart( state, payload ) {
            state.cart = payload.data;

            let products = await payload.loadProducts();
            state.cartQty = payload.getCartQty();
            state.cartSubtotal = await payload.getSubtotal();
            state.cartTax = await payload.getTax();
            state.cartShipping = payload.getShipping();
            state.cartDiscounts = await payload.getDiscounts();
            state.cartTotal = await payload.getTotal();
            state.cartProducts = products;
            state.cartCoupons = await payload.loadCoupons();
            state.cartNeedShipping = await payload.needShipping();
        }
    },
    actions: {
        async getCartData({ commit }) {
            let petuCart = new PetuCart();

            let customer = this.getters['auth/customer'];

            if( _st.isNUE( customer.id ) )
                await petuCart.load();
            else 
                await petuCart.loadFromCustomerId( customer.id ); // '048ddd4c-f898-4df0-a93b-57d8607ed950'

            commit( 'setCart', petuCart );
        },
        async addToCart({ dispatch, state }, { productId, qty }) {
            try {
                let petuCart = new PetuCart(state.cart)
                await petuCart.addProduct(productId, qty)

                dispatch('getCartData')
                return Promise.resolve('Success')
            } catch (err) {
                console.error('addToCart', err)
                return Promise.reject(error)
            }
        },
        async addCoupon( { dispatch, state }, couponId ) {
            try {
                let petuCart = new PetuCart( state.cart );
                let result = await petuCart.addCoupon( couponId );
                
                if( result )
                    return result;

                dispatch('getCartData');
            }
            catch( error ) {
                console.error( "addToCart", error );
            }
        },
        async setQty( { dispatch, state }, { productId, qty } ) {
            try {
                let petuCart = new PetuCart( state.cart );
                qty = parseInt( qty );

                if( qty <= 0 )
                    await petuCart.removeProduct( productId );
                else
                    await petuCart.setQty( productId, qty );

                dispatch('getCartData');
            }
            catch( error ) {
                console.error( "addToCart", error );
            }
        },
        async removeProduct( { dispatch, state }, productId ) {
            try {
                let petuCart = new PetuCart( state.cart );
                await petuCart.removeProduct( productId );

                dispatch('getCartData');
            }
            catch( error ) {
                console.error( "addToCart", error );
            }
        },
        async assignOrphanCart( { dispatch }, customerId ) {
            try {
                let petuCart = new PetuCart();
                await petuCart.load();
                await petuCart.assignOrphanCart( customerId );
                
                dispatch('getCartData');
            }
            catch( error ) {
                console.error( "assignOrphanCart", error );
            }
        },
        async clear( { dispatch, state } ) {
            try {
                let petuCart = new PetuCart( state.cart );
                await petuCart.clear();

                dispatch('getCartData');
            }
            catch( error ) {
                console.error( "addToCart", error );
            }
        }
    },
    getters: {
        cart            : (state) => state.cart,
        cartQty         : (state) => state.cartQty,
        cartSubtotal    : (state) => state.cartSubtotal,
        cartTax         : (state) => state.cartTax,
        cartShipping    : (state) => state.cartShipping,
        cartDiscounts   : (state) => state.cartDiscounts,
        cartTotal       : (state) => state.cartTotal,
        cartProducts    : (state) => state.cartProducts,
        cartCoupons     : (state) => state.cartCoupons,
        cartNeedShipping: (state) => state.cartNeedShipping
    }
}