import { ContactForm } from '@/classes/contactForm'

export const contactForm = {
    namespaced          : true,
    state               : { contactForms: [] },
    mutations: {
        setContactForms(state, payload) { state.contactForms = payload },
    },
    actions: {
        async getContactForms({ commit }) {
            let contactForm    = new ContactForm()
            let contactForms   = await contactForm.getList()
            
            commit('setContactForms', contactForms)
            
        }
    },
    getters: {
        contactForms: (state) => state.contactForms
    }
}