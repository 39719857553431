<template>
	<v-snackbar v-model="snackbar" rounded top :timeout="-1">
        <div class="d-flex align-center">
            <span class="mr-3"><v-icon :color="color">{{ icon }}</v-icon></span>
            <div class="font-weight-bold break">{{ message }}</div>
        </div>
	</v-snackbar>
</template>

<script>
import { bus } from '@/main';

export default {
	name: 'Alert',
	data: () => ({
        snackbar    : false,
        message     : '',
        status      : '',
        timeout     : null,
	}),
	mounted() {
        /** 
         *  alert event listener
         *  @param {String} status	- alert status
         *  @param {String} message - alert message
         */
        this.$root.$on('alert', ({ status, message }) => {
            this.message = message;
            this.status = status;
            this.snackbar = true;
            clearTimeout( this.timeout );
            this.timeout = setTimeout(() => { this.snackbar = false }, 4000);
        });
        bus.$on('alert', ({ status, message }) => {
            this.message = message;
            this.status = status;
            this.snackbar = true;
            clearTimeout( this.timeout );
            this.timeout = setTimeout(() => { this.snackbar = false }, 4000);
        });
    },
    beforeDestroy() { this.$root.$off('alert') },
	computed: {
        /** 
         *  alert icon
         *  @return {String} - icon for status
        */
        icon()  {
            switch( this.status ) {
                case 'success'      : return 'mdi-check-circle';
                case 'error'        : return 'mdi-alert';
                case 'punch-out'    : return 'mdi-exit-to-app';
                default             : return 'mdi-exclamation-circle';
            } 
        },
        /** 
         *  alert color
         *  @return {String} - color for status
        */
        color() {
            switch (this.status) {
                case 'success'  : return '#499937';
                case 'warning'  : return 'warning';
                case 'error'    : return 'error';
                default         : return 'orange';
            }
        }
	}
}
</script>
<style scoped>
.break {
    display: inline-block;
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-all;
}
</style>