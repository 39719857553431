/*
 * Author: Roberto Torres
 * Company: Softech Corporation
 * Version: 1.0.0
 * Date: 2021-05-10
 * 
 * Description: 
 * Class to facilitate the creation and manipulation of 
 * coupons in the Petu App
 * 
 */

import { API } from '@/inc/api';
import { _st } from '../softech';
import moment from 'moment';

export class PetuCoupon {
    constructor( coupon = null ) {
        if( _st.isNU( coupon ) ) {
            this.data = {
                id              : 0,
                code            : '',
                description     : '',
                amount          : 0,
                rules           : {
                    expirationDate  : null,
                    minItems        : 0,
                    canCombine      : false,
                    minCartAmount   : 0,
                    maxUse          : 1
                },
                type            : 'percentage',
                state           : 'draft',
                timesUsed       : 0,
                dateCreated     : null,
                lastUpdate      : null
            }
        }
        else 
            this.data = coupon;

        // helpers
        this.invalidReason = '';
    }

    async load( couponId = null ) {
        if( _st.isNUE( couponId ) ) {
            console.error( 'Coupon ID cannot be empty.' );
            return;
        }

        // load coupon from DB
        // const p = await API.graphql( { query: getCoupon, variables: { id: couponId }, authMode: 'API_KEY' } );

        try {
            let api = new API();

            let res = await api.get(`/coupon/${btoa(couponId)}`);

            if( res.data.status !== true ) {
                return Promise.reject( res );
            }

            this.data = res.data.data;
        }
        catch( error ) {
            return Promise.reject( error );
        }
    }
    async getList() {
        // const coupons = await API.graphql( graphqlOperation( listCoupons, { limit: 500 } ) );
        // return coupons.data.listCoupons.items;

        try {
            let api = new API();
            let res = await api.get('/coupons');

            if( res.data.status !== true )
                return [];

            return res.data.data;
        }
        catch(error) {
            console.log(error);
            return Promise.reject(error);
        }
    }
    async publish() {
        try {
            if( this.data.state == 'published' ) {
                console.log( 'Coupon already published' );
                return;
            }

            // save the new status into the DB
            this.data.state = 'published';
            await this.save();
        }
        catch( error ) {
            console.log( error );
        }
    }
    async unpublish() {
        try {
            if( this.data.state == 'draft' ) {
                console.log( 'Coupon is not published' );
                return;
            }

            // save the new status into the DB
            this.data.state = 'draft';
            await this.save();
        }
        catch( error ) {
            console.log( error );
        }
    }
    async save() {
        const now = this.now();

        // delete properties that are not allowed on the update
        delete this.data.createdAt;
        delete this.data.updatedAt;
        
        // create new coupon
        try {
            if( _st.isNUE( this.data.id ) ) {
                this.data.dateCreated = now;
                this.data.lastUpdate = now;
                this.data.amount = parseFloat( this.data.amount );
                this.data.rules.minItems = parseInt( this.data.rules.minItems );
                this.data.rules.minCartAmount = parseFloat( this.data.rules.minCartAmount );
                this.data.rules.maxUse = parseInt( this.data.rules.maxUse );
                this.data.timesUsed = parseInt( this.data.timesUsed );
                // this.data.id = uuidv4();
                
                // const p = await API.graphql( graphqlOperation( createCoupon, { input: this.data } ) );
                // this.data = p.data.createCoupon;
            }

            // update existing coupon
            else {
                this.data.lastUpdate = now;
                this.data.amount = parseFloat( this.data.amount );
                this.data.rules.minItems = parseInt( this.data.rules.minItems );
                this.data.rules.minCartAmount = parseFloat( this.data.rules.minCartAmount );
                this.data.rules.maxUse = parseInt( this.data.rules.maxUse );
                this.data.timesUsed = parseInt( this.data.timesUsed );

                // await API.graphql( graphqlOperation( updateCoupon, { input: this.data } ) );
            }

            let api = new API();
            let res = await api.post('/coupon', this.data);

            if( res.data.status !== true )
                return Promise.reject( res );

            this.data = res.data.data;

            return Promise.resolve( "Success" );
        }
        catch( error ) {
            console.log( "Error saving coupon", error );
            return Promise.reject(error);
        }
    }
    isUsable() {
        if( this.data.state != 'published' ) return false;
        if( !_st.isNUE( this.data.rules.expirationDate ) )
            return moment().isSameOrBefore( moment( this.data.rules.expirationDate ) );
        
        return true;
    }

    // Check if a coupon is valid for the cart
    async isValidForCart( cart ) {
        // 1. check if coupons is valid
        if( !this.isUsable() ) {
            this.invalidReason = 'Coupon is not available or expired';
            return false;
        }

        // // 2. load cart
        // const cart = new PetuCart();
        // await cart.loadFromCustomerId( '4161abf6-38cf-4bca-8406-7a4c5e17ab7b' ); // change the customer id for the correct variable
        
        // 3. check if it can be combined
        if( !this.data.rules.canCombine && cart.data.coupons.length > 0) {
            this.invalidReason = 'Coupon cannot be combined with other coupons';
            return false;
        }

        // 4. check if cart complies with coupon rules
        if( cart.data.products.length < this.data.rules.minItems ) { 
            this.invalidReason = `A minimum of ${this.data.rules.minItems} items are required to use this coupon`; 
            return false; 
        }
        if( await cart.getSubtotal() < this.data.rules.minCartAmount ) {
            this.invalidReason = `A subtotal of ${_st.toMoney(this.data.rules.minCartAmount)} or greater is required to use this coupon`; 
            return false;
        }
        if( this.data.timesUsed >= this.data.rules.maxUse ) {
            this.invalidReason = `The coupon use has maxed out`; 
            return false;
        }

        return true;
    }

    now() {
        var tzoffset = (new Date()).getTimezoneOffset() * 60000; // offset in milliseconds
        return (new Date(Date.now() - tzoffset)).toISOString(); // => '2015-01-26T06:40:36.181'
    }
}