import { PetuProduct } from '@/classes/product'

export const productsInfo = {
    namespaced: true,
    state: {
        products    : null,
    },
    mutations: {
        setProducts( state, payload ) {
            state.products = payload;
        },
        setCheckIns( state, payload ) {
            state.checkIns = payload;
        },
        setCart( state, payload ) {
            state.cart = payload;
        }
    },
    actions: {
        async createProduct( { dispatch }, newProduct ) {
            try {
                let product = new PetuProduct( newProduct );
                await product.save();

                dispatch('getProductsData');
            }
            catch( error ) {
                console.error( "createProduct", error );
                return Promise.reject(error);
            }
        },
        async getProductsData({ commit }) {
            try {
            let petuProduct = new PetuProduct();
            let products = await petuProduct.getList();
            commit( 'setProducts', products );
            }
            catch(error) {
                console.error("getProductsData", error);
                return Promise.reject(error);
            }
        }
    },
    getters: {
        products: (state) => state.products
    }
}