import { Auth } from "aws-amplify";
import { PetuCustomer } from '@/classes/customer'
import router from '@/router'
import { _st } from '@/softech';

export const auth = {

    namespaced: true,
    state: { 
		user        : null,
		customer    : JSON.parse(localStorage.getItem('petu-customer')) || {},
        profilePic  : localStorage.getItem('petu-profile-pic') || '',
        token       : _st.isNUE( localStorage.getItem('petu-auth') ) ? '' : localStorage.getItem('petu-auth')
    },
    mutations: {
        setUser(state, payload) {
            state.user = payload
            // localStorage.setItem('petu-user', JSON.stringify(payload))
        },
        setCustomer(state, payload) {
            state.customer = payload
            localStorage.setItem('petu-customer', JSON.stringify(payload))
        },
        setProfilePic(state, payload) {
            state.profilePic = payload
            localStorage.setItem('petu-profile-pic', payload)
        },
        setToken(state, payload) {
            state.token = payload;
            if( _st.isNUE( payload.length ) )
                localStorage.removeItem('petu-auth');
            else
                localStorage.setItem('petu-auth', payload);
        }
    },
    actions: {
        async logout({ commit, dispatch }) {
            commit('setUser', {});
            commit('setCustomer', {});
            commit('setProfilePic', '');
            commit('setToken', '');
            
            // clean cart
            await dispatch('cartInfo/getCartData', {}, {root:true});

            await Auth.signOut();

            router.push('/').catch((err) => {
                if (err.name != 'NavigationDuplicated') throw err
            });
        },
        async login({ commit, dispatch }, { username, password }) {
            try {
                let user        = await Auth.signIn({ username, password });
                user            = user.challengeName ? user : await Auth.currentUserInfo();

                let customer    = new PetuCustomer();
                await customer.loadFromEmail( user.attributes.email );

                if(_st.isEmpty(customer.data.id)) {
                    customer.data.firstName = user.attributes['given_name'];
                    customer.data.lastName = user.attributes['family_name'];
                    customer.data.phone = _st.toPhoneNumber(user.attributes['phone_number'].replace('+1', ''));
                    customer.data.email = user.attributes['email']
                    await customer.save();
                    await customer.getProfilePicSrc();
                }

                // create auth token
                // IMPORTANTE: TENGO QUE ARREGLAR ESTO TAN PRONTO PUEDA
                // ES UN ISSUE GRANDE DE SEGURIDAD
                // let jwt = require('jsonwebtoken');
                // let token = jwt.sign({
                //     exp: Math.floor(Date.now() / 1000) + (60 * 60 * 24 * 30),
                //     usr: customer.data.id
                // }, 'secret');
                let token = btoa( customer.data.id );
                commit('setToken', token);

                await dispatch('cartInfo/assignOrphanCart', customer.data.id, {root:true});
                commit('setUser', user);
                commit('setCustomer', customer.data);
                commit('setProfilePic', customer.image.imgUrl); // TODO: me quedé aquí ******************
                return Promise.resolve('Success')
            } 
            catch(error) {
                console.log(error)
                return Promise.reject(error)
            }
        },
        async completeNewPassword({ commit }, { username, oldPassword, newPassword}) {
            try {
                let user = await Auth.signIn({ username, password: oldPassword })
                
                if(user.challengeName === 'NEW_PASSWORD_REQUIRED') {
                    await Auth.completeNewPassword(user, newPassword)
                }

                const userInfo = await Auth.currentUserInfo();
                commit('setUser', userInfo);
                return Promise.resolve('Success');
            } catch(error) {
                console.log(error);
                return Promise.reject(error);
            }
        },
        async confirmSignUp(_, { username, code }) {
            try{
                await Auth.confirmSignUp(username, code)
                return Promise.resolve();
            } catch(error) {
                console.log(error)
                return Promise.reject(error);
            }
        },
        async signUp(_, { username, password, email, firstName, lastName, phone }) {
            try {
                await Auth.signUp({
                    username, 
                    password,
                    attributes: {
                        email,
                        given_name: firstName,
                        family_name: lastName,
                        phone_number: `+1${phone.replace('-','').replace('(','').replace(')','').replace(' ','')}`,
                        "custom:is_admin": "0"
                    }     
                });

                return Promise.resolve();
            } catch(error) {
                console.log(error);
                return Promise.reject(error);
            }
        },
        async federatedSignIn({ commit }, provider) {
            try {
                await Auth.federatedSignIn({provider: provider});

                const userInfo = await Auth.currentUserInfo();
                commit('setUser', userInfo);

                return Promise.resolve('Success');
            } catch(error) {
                console.log(error);
                return Promise.reject(error);
            }
        },
        async changePassword(_, { oldPassword, newPassword }) {
            try {
                let user = await Auth.currentAuthenticatedUser();
                return await Auth.changePassword(user, oldPassword, newPassword);
            } catch(error) {
                console.log(error);
                return Promise.reject(error);
            }
        },
        async forgotPassword(_, email) {
            try {
                return await Auth.forgotPassword(email);
            } catch(error) {
                console.log(error);
                return Promise.reject(error);
            }
        },
        async submitForgotPassword(_, { email, code, newPassword }) {
            try {
                return await Auth.forgotPasswordSubmit(email, code, newPassword);
            } catch(error) {
                console.log(error);
                return Promise.reject(error);
            }
        },
        async authAction({ commit }) {
            const userInfo = await Auth.currentUserInfo();
            commit('setUser', userInfo);
        },
		updateCustomer({ commit }, { customer }) {
            commit('setCustomer', customer.data);
            commit('setProfilePic', customer.image.imgUrl);
		},
        async reloadCustomer({ commit, getters }) {
            let customer = new PetuCustomer();
            await customer.loadFromEmail( getters.customer.email );
            commit('setCustomer', customer.data);
            commit('setProfilePic', customer.image.imgUrl);
        }
    },
    getters: {
        user                : (state) => state.user,
        customer            : (state) => state.customer,
        profilePic          : (state) => state.profilePic,
		isLoggedIn          : (state) => !_st.isNUE( state.user ),
        hasVacuID           : (state) => state.customer && !_st.isNUE( state.customer.vacuId ) && !_st.isNUE( state.customer.vacuId.bucket ) && !_st.isNUE( state.customer.vacuId.key ) && !_st.isNUE( state.customer.vacuId.region ),
        hasPaymentMethod    : (state) => state.customer && !_st.isNUE(state.customer.stripeCustomerId) && !_st.isNUE(state.customer.cards),
        token               : (state) => state.token,
        async isAdmin(state) {
            if (_st.isNUE(state.customer)) return false
            let customer = new PetuCustomer(state.customer)
            return await customer.isAdmin()
        },
        async isPetuPunchAdmin(state) {
            if (_st.isNUE(state.customer)) return false
            return await (new PetuCustomer(state.customer)).isPetuPunchAdmin()
        } 
    }
}