import { CustomerResource } from '@/classes/customerResource'

export const customerResource = {
    namespaced          : true,
    state               : { customerResources: [] },
    mutations: {
        setcustomerResources(state, payload) { state.customerResources = payload },
    },
    actions: {
        async getCustomerResources({ commit }) {
            let customerResource = new CustomerResource()
            commit('setcustomerResources', await customerResource.getList())
            
        }
    },
    getters: {
        customerResources: (state) => state.customerResources
    }
}