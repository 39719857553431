import { PetuOrder } from '../../classes/order'

export const ordersInfo = {
    namespaced: true,
    state: {
        orders      : null,
    },
    mutations: {
        setOrders( state, payload ) {
            state.orders = payload;
        },
    },
    actions: {
        async getOrdersData({ commit }, { from, to, status }) {
            let petuOrder = new PetuOrder();
            const ordersData = await petuOrder.getList( from, to, status );
            commit( 'setOrders', ordersData );
        },
        // async getFilteredOrdersData({ commit }, { filter, limit }) {
        //     let petuOrder = new PetuOrder();
        //     const ordersData = await petuOrder.getList({ filter, limit });
        //     commit( 'setOrders', ordersData );
        // }
    },
    getters: {
        orders  : (state) => state.orders,
        // {
        //     if( state.orders === null ) return [];
        //     return state.orders.sort((a,b) => (a.orderDate <= b.orderDate) ? 1 : ((b.orderDate <= a.orderDate) ? -1 : 0));
        // }
    }
}