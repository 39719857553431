/*
 * Author: Roberto Torres
 * Company: Softech Corporation
 * Version: 1.0.0
 * Date: 2021-06-18
 * 
 * Description: 
 * Class to facilitate the creation and manipulation of 
 * orders in the Petu App
 * 
 */

import { _st } from '@/softech';
import { OrderStatus } from '@/models';
import { API } from '@/inc/api';
import { PetuCustomer } from './customer';
import { StripeGateway } from "./gateways/stripe";

export class PetuOrder {
    constructor(order = null) {
        if(_st.isNU(order))
            this.data = {
                id                      : '',
                orderNumber             : '',
                orderDate               : null,
                customerId              : '',
                products                : [],
                subtotal                : 0,
                discounts               : 0,
                shipping                : 0,
                tax                     : 0,
                total                   : 0,
                coupons                 : [],
                notes                   : [],
                paymentMethod           : '',
                paymentConfirmation     : '',
                status                  : OrderStatus.DRAFT,
                shippingCarrier         : '',
                trackingNumber          : '',
                fromSubscriptionId      : '',
                lastUpdate              : null
            };
        else
            this.data = order;

        // helpers
        this.errors = [];
    }

    async load( orderId = null ) {
        if( _st.isEmpty( orderId ) ) {
            console.error('Order ID cannot be empty.');
            return;
        }

         // load product from DB
         try {
            let api = new API();
            let res = await api.get(`/order/${orderId}`);

            if( res.data.status !== true ) {
                return Promise.reject( res );
            }

            this.data = res.data.data;
        }
        catch( error ) {
            return Promise.reject( error );
        }

        // try {
        //     // load order from DB
        //     const o = await API.graphql(graphqlOperation(getOrder, { id: orderId }));
        //     this.data = o.data.getOrder;

        //     if(_st.isNU(this.data))
        //         throw 'Order not found';

        //     let prodFilters = [];
        //     let coupFilters = [];
        //     let prodIds = _st.extractProp(this.data?.products, 'productId');
        //     prodIds.forEach(pId => { prodFilters.push({ id: { eq: pId } }); });
        //     this.data.coupons.forEach(cId => { coupFilters.push({ id: { eq: cId } }); });

        //     const p = await API.graphql(graphqlOperation(listProducts, { filter: { or: prodFilters } }));
        //     this.data.productsObj = p.data.listProducts.items;

        //     if(this.data.coupons.length > 0) {
        //         const c = await API.graphql(graphqlOperation(listCoupons, { filter: { or: coupFilters } }));
        //         this.data.couponsObj = c.data.listCoupons.items;
        //     }

        //     return Promise.resolve();
        // }
        // catch(error) {
        //     await (new PetuErrorLog()).logError({method: 'PetuOrder/load', error: error, stackTrace: error.stack});
        //     return Promise.reject(error);
        // }
    }

    // async getList({ filter, limit, sortDirection }) {
    //     try {
    //         let variables = {};
    //         if(!_st.isNUE(filter)) variables.filter = filter;
    //         if(!_st.isNUE(limit)) variables.limit = limit;
    //         if(!_st.isNUE(sortDirection)) variables.sortDirection = sortDirection;

    //         let orders = null;
    //         if(_st.isNUE(variables))
    //             orders = await API.graphql(graphqlOperation(listOrders));
    //         else
    //             orders = await API.graphql(graphqlOperation(listOrders, variables));

    //         return orders.data.listOrders.items;
    //     }
    //     catch(error) {
    //         console.log(error);
    //         await (new PetuErrorLog()).logError({method: 'PetuOrder/getList', error: error, stackTrace: error.stack});
    //         return Promise.reject(error);
    //     }
    // }

    async getList( from = null, to = null, status = 'all' ) {
        try {
            let api = new API();

            let args = '';
            if( !_st.isNUE( status ) ) {
                args = `/${status}`;
            }
            if( !_st.isNUE( from ) ) {
                if( !_st.isNUE( to ) ) 
                    args += `/${from}/${to}`;
                else 
                    args += `/${from}`;
            }

            let res = await api.get(`/order/list${args}`);

            if( res.data.status !== true ) {
                return Promise.reject( res );
            }

            return res.data.data;
        } 
        catch(error) {
            return Promise.reject( error );
        }
    }

    // async getListByDate({ filter, limit, sortDirection }) {
    //     try {
    //         let variables = {};
    //         if(!_st.isNUE(filter)) variables.filter = filter;
    //         if(!_st.isNUE(limit)) variables.limit = limit;
    //         if(!_st.isNUE(sortDirection)) variables.sortDirection = sortDirection;

    //         let orders = null;
    //         if(_st.isNUE(variables))
    //             orders = await API.graphql(graphqlOperation(listOrders));
    //         else
    //             orders = await API.graphql(graphqlOperation(listOrders, variables));

    //         return orders.data.listOrders.items;
    //     }
    //     catch(error) {
    //         console.log(error);
    //         await (new PetuErrorLog()).logError({method: 'PetuOrder/getList', error: error, stackTrace: error.stack});
    //         return Promise.reject(error);
    //     }
    // }

    async save() {
        this.errors = [];

        if( !this.validate() )
            return Promise.reject( this.errors );

        // delete properties that are not allowed on the update
        delete this.data.productsObj;
        delete this.data.couponsObj;
        delete this.data.customer;
        delete this.data.orders;
        delete this.data.createdAt;
        delete this.data.updatedAt;
        
        // create new order
        try {
            // if( _st.isNUE( this.data.id ) ) {
            //     this.data.orderDate = now;
            //     this.data.lastUpdate = now;
            //     this.data.subtotal = _st.toFixedNumber(this.data.subtotal, 2);
            //     this.data.discounts = _st.toFixedNumber(this.data.discounts, 2);
            //     this.data.shipping = _st.toFixedNumber(this.data.shipping, 2);
            //     this.data.tax = _st.toFixedNumber(this.data.tax, 2);
            //     this.data.total = _st.toFixedNumber(this.data.total, 2);
            //     this.data.id = uuidv4();
            //     this.data.orderNumber = `petu-${moment().format('YYYYMMDD')}-${moment().format('ssHHmmSSS')}`;
            //     // console.log(this.data)
            //     const p = await API.graphql(graphqlOperation(createOrder, { input: this.data }));
            //     this.data = p.data.createOrder;
            // }

            // // update existing order
            // else {
            //     this.data.lastUpdate = now;
            //     this.data.subtotal = _st.toFixedNumber(this.data.subtotal, 2);
            //     this.data.discounts = _st.toFixedNumber(this.data.discounts, 2);
            //     this.data.shipping = _st.toFixedNumber(this.data.shipping, 2);
            //     this.data.tax = _st.toFixedNumber(this.data.tax, 2);
            //     this.data.total = _st.toFixedNumber(this.data.total, 2);

            //     await API.graphql(graphqlOperation(updateOrder, { input: this.data }));
            // }

            
            this.data.subtotal = _st.toFixedNumber( this.data.subtotal, 2 );
            this.data.discounts = _st.toFixedNumber( this.data.discounts, 2 );
            this.data.shipping = _st.toFixedNumber( this.data.shipping, 2 );
            this.data.tax = _st.toFixedNumber( this.data.tax, 2 );
            this.data.total = _st.toFixedNumber( this.data.total, 2 );
            
            let api = new API();
            let res = await api.post( `/order`, this.data );

            if( res.data.status !== true ) {
                return Promise.reject( res );
            }
            
            this.data = res.data.data;

            return Promise.resolve("Success");
        }
        catch( error ) {
            console.log("Error saving order", error);
            return Promise.reject( error );
        }
    }
    
    async validate(cart) {
        
        // check if the cart is empty
        if(_st.isNU(cart) || _st.isNUE(cart.data) || _st.isEmpty(cart.data.products))
            return 'Cart is empty';

        let errors = [];
        let needShipping = cart.needShipping();

        // validate customer information
        let customer = new PetuCustomer();
        await customer.load( this.data.customerId );
        if( _st.isNUE( customer.data.firstName) )
            errors.push( 'Falta el primer nombre del cliente' );
        if( _st.isNUE( customer.data.lastName ) ) 
            errors.push( 'Falta el apellido del cliente.' );
        if( _st.isNUE( customer.data.email) )
            errors.push( 'Falta el email del cliente' ); 
        if( _st.isNUE( customer.data.phone) )
            errors.push( 'Falta el número de teléfono del cliente.' );
        if( needShipping && _st.isNUE( customer.data.shippingAddress ) )
            errors.push( 'Falta la dirección de envío del cliente' );

        // validate cart
        let cartValid = await cart.validate();
        if( cartValid !== true ) 
            errors.concat( cartValid );

        if( errors.length > 0 ) {
            this.errors = errors;
            return false;
        }

        return true;
    }

    async process( paymentMethod, meta, cardIx = null ) {
        try {
            let extraData = { paymentMethod };
            if( paymentMethod === 'stripe' )
                extraData.cardToken = meta;
            else if( paymentMethod === 'athmovil' )
                extraData.paymentConfirmation = meta;

            let api = new API();
            let res = await api.post( `/order/process`, { 
                ...extraData,
                cardIx
            });

            if( res.data.status !== true ) {
                return Promise.reject( res );
            }
            
            this.data = res.data.data;
            return Promise.resolve();
        }
        catch(error) {
            console.log("Error processing the order", error);
            return false;
        }
    }

    async renewSubscription() {
        try {
            let customer = new PetuCustomer();
            await customer.load( this.data.customerId )

            if (this.data.total < 0) return Promise.reject('El total de la orden no puede ser menor a 0.');
            if (_st.isNUE(customer.data.stripeInfo) || _st.isNUE(customer.data.stripeInfo.customerId) || _st.isNUE(customer.data.stripeInfo.payment.id)) 
                return Promise.reject('Cliente no tiene una tarjeta de crédito.')

            this.data.status = OrderStatus.PENDING_PAYMENT;
            this.save();

            // process payment only if there is an amount to be chargeD
            if(this.data.total > 0) {
                console.log(customer.data.stripeInfo)

                let stripe = new StripeGateway({});
                stripe.customer = customer.data.stripeInfo.customerId;
                stripe.card = customer.data.stripeInfo.payment.id;
                
                let charge = await stripe.charge({
                    amount: this.data.total,
                    description: `Payment for order #${this.data.orderNumber}`
                });

                this.data.paymentConfirmation = JSON.stringify(charge); // charge.id;
            }

            this.data.status = OrderStatus.PROCESSING;
            await this.save();
        }
        catch(error) {
            console.error('Error creating subscription renewal order', error);
            return Promise.reject(error);
        }
    }

    async addNote( message ) {
        try {
            let api = new API();
            let res = await api.post('/order/note', {
                orderId: this.data.id,
                message: message
            });

            if( res.data.status !== true ) {
                return Promise.reject( res );
            }

            this.data.notes.push( res.data.data );
        }
        catch(error) {
            console.error('Error añadiendo nota', error);
            return Promise.reject(error);
        }
    }
    removeNote( ix ) {
        this.data.notes.splice( ix, 1 );
    }

    async changeStatus( status ) {
        if(status != OrderStatus.PROCESSING.toLowerCase() && status != OrderStatus.COMPLETED.toLowerCase() &&
            status != OrderStatus.CANCELLED.toLowerCase() && status != OrderStatus.PENDING_PAYMENT.toLowerCase() &&
            status != OrderStatus.SHIPPED.toLowerCase() && status != OrderStatus.FAILED.toLowerCase() &&
            status != OrderStatus.DRAFT.toLowerCase() && status != OrderStatus.REFUNDED.toLowerCase()) {
            console.error('Invalid order status provided.', status);
            return;
        }

        if( status == OrderStatus.PENDING_PAYMENT ) 
            status = 'pending payment';

        this.data.status = status.toLowerCase();
        await this.save();
    }

    async delete() {
        if( _st.isEmpty( this.data.id ) ) 
            return false;

        try {
            let api = new API();
            let res = await api.post( `/order/delete`, { orderId: this.data.id } );

            if( res.data.status !== true ) {
                return Promise.reject( res );
            }

            return Promise.resolve("Success");
        }
        catch(error) {
            console.log("Error deleting order", error);
            return Promise.reject(error); 
        }
    }
}
