/*
 * Author: Roberto Torres
 * Company: Softech Corporation
 * Version: 2.0.0
 * Date: 2023-10-02
 * 
 * Description: 
 * Class to facilitate the creation and manipulation of 
 * customers in the Petu App
 * 
 */

import { _st } from '@/softech';
import awsconfig from "@/aws-exports";
import store from '@/store';
import { API } from '@/inc/api';
import { v4 as uuidv4 } from 'uuid';
import { Storage } from 'aws-amplify';

export class PetuCustomer {
    constructor( customer = null ) {
        if( _st.isNU( customer ) ) {
            this.data = {
                id                  : '',
                firstName           : '',
                lastName            : '',
                email               : '',
                phone               : null,
                profilePic          : { bucket: '', key: '', region: '' },
                gender              : '',
                height              : '',
                weight              : '',
                weightGoal          : '',
                interests           : [],
                billingAddress1     : '',
                billingAddress2     : '',
                billingCity         : '',
                billingState        : '',
                billingZipcode      : '',
                shippingAddress1    : '',
                shippingAddress2    : '',
                shippingCity        : '',
                shippingState       : '',
                shippingZipcode     : '',
                stripeCustomerId    : '',
                subscriptions       : [],
                cards               : [],
                resources           : [],
                checkIns            : [],
                orders              : [],
                progress            : [],
                strikes             : 0,
                isAdmin             : false,
                isPetuPunchAdmin    : false,
                pin                 : null,
                vacuId              : { bucket: '', key: '', region: '' },
                dateCreated         : null,
                lastLogin           : null,
            }
        } 
        else {
            this.data = customer;
        }

        this.srcResources   = [];
        this.image = {
            name    : '',
            size    : '',
            imgUrl  : '',
            data    : null,
            primary : true,
            order   : 0,
            isNew   : false,
        };
        this.vacuIdImage = {
            name    : '',
            size    : '',
            imgUrl  : '',
            data    : null,
            primary : true,
            order   : 0,
            isNew   : false,
        };
    }

    async load( customerId = null ) {
        if( _st.isEmpty( customerId ) ) {
            return;
        }

        // load product from DB
        try {
            let api = new API();
            let res = await api.get(`/customer/${customerId}`);

            if( res.data.status !== true ) {
                return Promise.reject( res );
            }

            this.data = res.data.data;
        }
        catch( error ) {
            return Promise.reject( error );
        }
    }

    async loadFromEmail( email = null ) {
        if( _st.isEmpty( email ) ) 
            return;

        try {
            email = email.toLowerCase();
            
            let api = new API();
            let res = await api.get(`/customer/email/${btoa(email)}`);

            if( res.data.status !== true ) {
                return Promise.reject( res );
            }

            this.data = res.data.data;
            await this.getProfilePicSrc();
            await this.getVacuIDSrc();
        }
        catch( error ) {
            return Promise.reject( error );
        }
    }

    async loadFromPin( pin = null ) {
        if( _st.isEmpty( pin ) ) 
            return;

        try {
            email = email.toLowerCase();
            let res = await api.get(`/customer/pin/${pin}`);

            if( res.data.status !== true ) {
                return Promise.reject( res );
            }

            this.data = res.data.data;
        }
        catch( error ) {
            return Promise.reject( error );
        }
    }

    async getList( from = null, to = null ) {
        try {
            let api = new API();

            let args = '';
            if( !_st.isNUE( from ) ) {
                if( !_st.isNUE( to ) ) 
                    args = `/${from}/${to}`;
                else 
                    args = `/${from}`;
            }

            let res = await api.get(`/customer/list${args}`);

            if( res.data.status !== true ) {
                return Promise.reject( res );
            }

            return res.data.data;
        } 
        catch(error) {
            return Promise.reject( error );
        }
    }

    async getListLimit( limit = 0, offset = 0 ) {
        try {
            let api = new API();

            let args = '';
            if( !_st.isNUE( limit ) ) {
                if( !_st.isNUE( offset ) ) 
                    args = `/${limit}/${offset}`;
                else 
                    args = `/${limit}`;
            }

            let res = await api.get(`/customer/list-limit${args}`);

            if( res.data.status !== true ) {
                return Promise.reject( res );
            }

            return {
                items: res.data.data,
                total: res.data.total
            };
        } 
        catch(error) {
            return Promise.reject( error );
        }
    }

    async save() {
        delete this.data.subscriptions;
        delete this.data.cart;
        delete this.data.orders;
        delete this.data.checkIns;
        delete this.data.resources;
        delete this.data.progress;
        delete this.data.createdAt;
        delete this.data.updatedAt;

        try {
            let api = new API();

            // if( _st.isNUE( this.data.id ) ) {
                this.data.email = this.data.email.toLowerCase();

                if( this.image && this.image.data && this.image.isNew ) {
                    await this.deleteImage( this.data.profilePic ); // delete old profile pic
                    this.data.profilePic = await this.createImage( this.image ); // create new one
                }
                if( this.vacuIdImage && this.vacuIdImage.data && this.vacuIdImage.isNew ) {
                    await this.deleteImage( this.data.vacuId ); // delete old vacu id pic
                    this.data.vacuId = await this.createImage( this.vacuIdImage, false ); // crete new one
                }

                // for (let rsc of this.srcResources) {
                //     await this.saveResource(rsc);
                // }
            
                let res = await api.post( `/customer`, this.data );

                if( res.data.status !== true ) {
                    return Promise.reject( res );
                }
                
                this.data = res.data.data;
            // } else {
            //     if (this.image && this.image.data) {
            //         await this.deleteImage(this.image)
            //         await this.createImage(this.image)
            //     }

            //     if (this.vacuIdImage && this.vacuIdImage.data) {
            //         await this.deleteImage(this.vacuIdImage)
            //         await this.createImage(this.vacuIdImage, false)
            //     }

            //     this.data.email = this.data.email.toLowerCase()
            //     this.data.searchString = `${this.data.firstName.toLowerCase()}${this.data.lastName.toLowerCase()}${this.data.email}` 
            //     console.log(this.data)
            //     await API.graphql(graphqlOperation(updateCustomer, { input: this.data }));
            // }
        } 
        catch( error ) {
            if( this.image.isNew ) {
                this.deleteImage( this.data.profilePic );
            }
            if( this.vacuIdImage.isNew ) {
                this.deleteImage( this.data.vacuId );
            }
            return Promise.reject( error );
        }
    }

    getProfilePicSrc() {
        try {
            if( _st.isNUE( this.data.profilePic.key ) ) 
                return '';

            this.image = {
                name    : this.data.profilePic.key.substr(this.data.profilePic.key.lastIndexOf('/') + 1),
                size    : '',
                imgUrl  : `https://${this.data.profilePic.bucket}.s3.amazonaws.com/public/${this.data.profilePic.key}`,
                data    : null,
                primary : true,
                order   : 0,
                isNew   : false,
            };
            return this.image.imgUrl;
        } 
        catch(error) {
            console.error('Error getting profile picture:', error);
            return '';
        }
    }

    async getVacuIDSrc() {
        try {
            this.vacuIdImage = {};
            if( _st.isNUE( this.data.vacuId ) || _st.isNUE( this.data.vacuId.key ) ) 
                return Promise.resolve('Success');

            const imgUrl = await Storage.get( this.data.vacuId.key, { level: 'protected', expires: 180, identityId: this.data.vacuId.identityId });
            this.vacuIdImage = {
                name    : this.data.vacuId.key.substr( this.data.vacuId.key.lastIndexOf('/') + 1 ),
                size    : '',
                imgUrl,
                data    : null,
                primary : true,
                order   : 0,
                isNew   : false,
            }
            return Promise.resolve('Success')
        } 
        catch(error) {
            console.error( 'Error getting vacu id picture:', error );
            return Promise.reject( error );
        }
    }

    async getResourcesSrc() {
        try {
            this.srcResources = [];
            for (let rsc of this.data.resources) {
                const rscUrl = await Storage.get(rsc.src.key, { level: "protected", expires: 180 });

                this.srcResources.push({
                    id              : rsc.id,
                    name            : rsc.name,
                    type            : rsc.type,
                    src             : rsc.src,
                    description     : rsc.description,
                    instructions    : rsc.instructions,
                    onlyOn          : rsc.onlyOn,
                    availableOn     : rsc.availableOn,
                    resource        : null,
                    isNew           : false,
                    isEdit          : false,
                    rscUrl          : rscUrl
                });
            }

            return Promise.resolve('Success')
        } catch(error) {
            console.log('Error getting product resources', error)
            return Promise.reject(error)
        }
    }

    async getSrc( resource = null ) {
        try {
            if( _st.isNUE( resource ) )
                return '';

            const src = await Storage.get(resource.key, { level: "protected", expires: 180 });
            return Promise.resolve( src );
        } catch(error) {
            console.log('Error getting product resources', error)
            return Promise.reject(error)
        }
    }

    async createImage( img, isProfilePic = true ) {
        const { 'aws_user_files_s3_bucket': bucket, 'aws_user_files_s3_bucket_region': region } = awsconfig;
        const { type: mimeType } = img.data;
        const extension = img.data.name.substr( img.data.name.lastIndexOf('.') + 1 );
        const id = uuidv4();
        const folder = isProfilePic ? 'profilePictures' : 'vacuIds';
        const secLevel = isProfilePic ? 'public' : 'protected';
        const property = isProfilePic ? 'profilePic' : 'vacuId';

        const key = `${folder}/${id}.${extension}`;

        try {
            await Storage.put(key, img.data, {
                level: secLevel,
                contentType: mimeType,
                metadata: { customerId: this.data.id.toString() }
            });

            let data = null;
            if( isProfilePic ) {
                data = { bucket, key, region };
            }
            else {
                let user = store.getters['auth/user'];
                if( _st.isNU( user ) )
                    user = { id: '0' };

                data = { bucket, key, region, identityId: user.id };
            }

            return Promise.resolve( data );
        } catch(error) {
            console.log('Error creating image', error);
            return Promise.reject(error);
        }
    }

    // Se hizo este método para subir las imagenes del progreso del customer
    // sin dañar el método ya hecho para manejar los profile pics y los vacuids (createImage)
    // Se usa en: saveProgress()
    async uploadImage(img, folder) {
        const { 'aws_user_files_s3_bucket': bucket, 'aws_user_files_s3_bucket_region': region } = awsconfig
        const { type: mimeType } = img.data
        const extension = img.data.name.substr(img.data.name.lastIndexOf('.') + 1);
        const id = uuidv4();
        const secLevel = 'protected';

        const key = `${folder}/${id}.${extension}`;
        
        try {
            await Storage.put(key, img.data, {
                level: secLevel,
                contentType: mimeType,
                metadata: { customerId: this.data.id.toString() }
            });

            let user = store.getters['auth/user'];
            if(_st.isNU(user))
                user = { id: '' };

            return { id, bucket, key, region, identityId: user.id, extension: mimeType };
        } 
        catch(error) {
            console.log('Error creating image', error);
            return Promise.reject(error);
        }
    }

    async deleteImage( img ) {
        try {
            const key = this.data.profilePic.key.substr( 0, this.data.profilePic.key.lastIndexOf('.') );
            const ext = this.data.profilePic.key.substr( this.data.profilePic.key.lastIndexOf('.') + 1 );

            if( !_st.isNUE( this.data.profilePic ) && img.key === this.data.profilePic.key ) {
                await Storage.remove( `${key}.${ext}` ); // main
                await Storage.remove( `${key}-medium.${ext}` ); // medium thumbnail
                await Storage.remove( `${key}-small.${ext}` ); // small thumbnail

                this.data.profilePic = { bucket: '', key: '', region: '', identityId: '' };
            } 
            else if( !_st.isNUE( this.data.vacuId ) && img.key === this.data.vacuId.key ) {
                await Storage.remove( `${key}.${ext}`, { level: 'protected' } ); // main
                await Storage.remove( `${key}-medium.${ext}`, { level: 'protected' } ); // medium thumbnail
                await Storage.remove( `${key}-small.${ext}`, { level: 'protected' } ); // small thumbnail
                this.data.vacuId = { bucket: '', key: '', region: '', identityId: '' };
            }

            return Promise.resolve('Success');
        } 
        catch(error) {
            console.error('Error deleting image:', error);
            return Promise.reject(error);
        }
    }

    async uploadResource( customerResource ) {            
        try {
            const { 'aws_user_files_s3_bucket': bucket, 'aws_user_files_s3_bucket_region': region } = awsconfig
            const now = this.now();
            const rscId = uuidv4();
            customerResource.id = rscId;
            customerResource.dateCreated = now;
            
            const { type: mimeType } = customerResource.resource
            const extension = customerResource.resource.name.substr(customerResource.resource.name.lastIndexOf('.') + 1)
            const key = `customerResources/${rscId}.${extension}`
            
            let evidence = await Storage.put(key, customerResource.resource, {
                level: 'protected',
                contentType: mimeType,
                metadata: { 
                    resourceId: customerResource.resourceID.toString(),
                    productId: customerResource.productID.toString(),
                    customerId: customerResource.customerID.toString(),
                }
            });

            let user = store.getters['auth/user'];
            if( _st.isNU( user ) )
                user = { id: '' };

            let api = new API();
            let res = await api.post('/customer/resource', {
                productId: customerResource.productID,
                resourceId: customerResource.resourceID,
                src: {
                    bucket      : bucket,
                    key         : key,
                    region      : region,
                    identityId  : user.id
                }
            });

            if( res.data.status !== true ) {
                return Promise.reject( res.data.message );
            }

            // this.data.resources.push( res.data.data );

            return Promise.resolve(res.data.data);
        } 
        catch (error) {
            console.log('Error saving subscription', error)
            return Promise.reject(error)
        }
    }

    getFullName() {
        return `${this.data.firstName} ${this.data.lastName}`;
    }

    getInitials() {
        return this.data.firstName && this.data.lastName
        ? `${this.data.firstName[0].toUpperCase()}${this.data.lastName[0].toUpperCase()}`
        : ''
    }
    async getActiveSubscriptions() {
        try {
            let api = new API();

            let res = await api.get(`/customer/${Buffer.from(this.data.id)}/subscriptions/active`);

            if( res.data.status !== true ) {
                return Promise.reject( res );
            }

            return Promise.resolve( res.data.data );
        }
        catch( error ) {
            return Promise.reject( error );
        }

        // let subscriptions = new PetuSubscription();
        // let activeSubscriptions = subscriptions.getListByCustomer({
        //     customerID: this.data.id,
        //     filter: {
        //         remainingSessions: { gt: 0 },
        //         nextPaymentDate: { ge: moment().format('YYYY-MM-DD') }
        //     }
        // });

        // return activeSubscriptions
    }
    async hasActiveSubscriptions() {
        return this.getActiveSubscriptions().length > 0;
    }
    hasPaymentMethod() {
        return !_st.isEmpty(this.data.stripeInfo.customerId) && !_st.isEmpty(this.data.stripeInfo.paymentId);
    }
    async getOrders( limit = null ) {
        try {
            let api = new API();
            let res = await api.get(`/customer/${btoa(this.data.id)}/orders${limit == null ? '' : '/' + limit}`);

            if( res.data.status !== true ) {
                return Promise.reject( res );
            }

            this.data.orders = res.data.data;
            return Promise.resolve( res.data.data );
        }
        catch( error ) {
            return Promise.reject( error );
        }
        // let os  = await API.graphql(graphqlOperation(customerOrders, { customerID: this.data.id }))
        // os      = os.data.customerOrders.items
        // os      = os.sort((a,b) => new Date(b.createdAt) - new Date(a.createdAt))
        // return limit && limit > 0 ? os.slice(0, limit) : os 
    }
    async getOrder( orderId ) {
        try {
            let api = new API();
            let res = await api.get(`/customer/${btoa(this.data.id)}/order/${orderId}`);

            if( res.data.status !== true ) {
                return Promise.reject( res );
            }

            return Promise.resolve( res.data.data );
        }
        catch( error ) {
            return Promise.reject( error );
        }
        // const order = await API.graphql(graphqlOperation(getOrder, { id: orderId }));
        // return _st.isNUE(order.data.getOrder) ? {} : order.data.getOrder;
    }

    async getSubscriptions() {
        try {
            let api = new API();
            let res = await api.get(`/customer/${btoa(this.data.id)}/subscriptions`);

            if( res.data.status !== true ) {
                return Promise.reject( res );
            }

            this.data.subscriptions = res.data.data;
            return Promise.resolve( res.data.data );
        }
        catch( error ) {
            return Promise.reject( error );
        }

        // let ss  = await API.graphql(graphqlOperation(getSubscriptionByCustomer, { customerID: this.data.id }));
        // ss      = ss.data.subscriptionByCustomer.items
        // ss      = ss.sort((a,b) => new Date(b.createdAt) - new Date(a.createdAt))
        // return limit && limit > 0 ? ss.slice(0, limit) : ss
    }

    async getResources() {
        try {
            let api = new API();
            let res = await api.get(`/customer/${btoa(this.data.id)}/resources`);

            if( res.data.status !== true ) {
                return Promise.reject( res );
            }

            return Promise.resolve( res.data.data );
        }
        catch( error ) {
            return Promise.reject( error );
        }

        // const r = await API.graphql(graphqlOperation(resourceByCustomer, { customerID: this.data.id }));
        // return r.data.resourceByCustomer.items;
    }

    async getCheckIns( limit = null ) {
        try {
            let api = new API();
            let res = await api.get(`/customer/${btoa(this.data.id)}/checkins${limit == null ? '' : '/' + limit}`);

            if( res.data.status !== true ) {
                return Promise.reject( res );
            }

            return Promise.resolve( res.data.data );
        }
        catch( error ) {
            return Promise.reject( error );
        }
        // let cs  = await API.graphql(graphqlOperation(checkinByCustomer, { customerID: this.data.id }));
        // cs      = cs.data.checkinByCustomer.items 
        // cs      = cs.sort((a,b) => new Date(b.createdAt) - new Date(a.createdAt))
        // return limit && limit > 0 ? cs.slice(0, limit) : cs
    }

    async getProgress( limit = null ) {
        try {
            let api = new API();
            let res = await api.get(`/customer/${btoa(this.data.id)}/progress${limit == null ? '' : '/' + limit}`);

            if( res.data.status !== true ) {
                return Promise.reject( res );
            }

            return Promise.resolve( res.data.data );
        }
        catch( error ) {
            return Promise.reject( error );
        }

        // let progress = await API.graphql(graphqlOperation(progressByCustomer, { customerID: this.data.id }));
        // progress = progress.data.progressByCustomer.items;
        // progress = progress.sort((a,b) => new Date(b.dateCreated) - new Date(a.dateCreated));

        // for( let i = 0; i < progress.length; i++ ) {
        //     for( let j = 0; j < progress[i].images.length; j++ ) {
        //         progress[i].images[j].rscUrl = await Storage.get(progress[i].images[j].src.key, { level: "protected", expires: 180 });
        //         progress[i].images[j].rscUrlSmall = await Storage.get(progress[i].images[j].smallThumbnail.key, { level: "protected", expires: 180 });
        //         progress[i].images[j].rscUrlMedium = await Storage.get(progress[i].images[j].mediumThumbnail.key, { level: "protected", expires: 180 });
        //     }
        // }

        // return limit && limit > 0 ? cs.slice(0, limit) : progress;
    }

    async saveProgress( data ) {
        try {
            // save images
            let img = null;
            console.log(data.image.resource);
            console.log(_st.isNU( data.image.resource ));
            if( !_st.isNU( data.image.resource ) ) {
                let tmp = {
                    data: data.image.resource
                };
                img = await this.uploadImage( tmp, 'progress' );
            }

            let api = new API();
            let res = await api.post('/customer/progress', {
                customerId  : this.data.id,
                weight      : data.weight,
                chest       : data.chest,
                waist       : data.waist,
                hips        : data.hips,
                biceps      : data.biceps,
                thighs      : data.thighs,
                date        : data.date,
                image       : img
            });


            if( res.data.status !== true ) {
                // presentar error
                return Promise.reject( res.data.message );
            }
            
            return Promise.resolve();
        }
        catch(error) {
            console.log('Error saving progress', error)
            return Promise.reject(error);
        }
    }

    async deleteProgress( progress ) {
        try {
            // remove the images first
            const key = progress.image.key.substr( 0, progress.image.key.lastIndexOf('.') );
            const ext = progress.image.key.substr( progress.image.key.lastIndexOf('.') + 1 );

            await this.deleteImage({ key: `${key}.${ext}` });
            await this.deleteImage({ key: `${key}-medium.${ext}` });
            await this.deleteImage({ key: `${key}-small.${ext}` });

            let api = new API();
            let res = await api.post('/customer/progress/delete', {
                customerId: this.data.id,
                progressId: progress.id
            });

            if( res.data.status !== true ) {
                // presentar error
                return Promise.reject( res.data.message );
            }
            
            return Promise.resolve('success');
        }
        catch(error) {
            return Promise.reject(error);
        }
    }

    renewSubscription() {
        // create new order 
    }
    async checkIn(sessionId = null) {
        try {
            if( _st.isNUE( this.data.id ) ) {
                return Promise.reject({
                    error: true,
                    code: 'notloggedin',
                    goto: '/login',
                    message: 'Customer is not signed in.'
                });
            }
            
            if( _st.isNUE( sessionId ) ) {
                return Promise.reject({
                    error: true,
                    goto: '',
                    message: 'La sesión no existe.'
                });
            }

            let api = new API();
            let res = await api.post('/customer/checkin', { sessionId });

            if( res.data.status !== true ) {
                if( res.data.error ) {
                    // show error
                    return;
                }

                return Promise.reject( res.data );
            }
            
            return Promise.resolve( res.data.data );

            // // get session information
            // let session = new PetuSession();
            // await session.load( sessionId );
            
            // if( _st.isNUE( session.data.id ) ) {
            //     return Promise.reject({
            //         error: true,
            //         code: 'dontexist',
            //         goto: '',
            //         message: 'La sesión no existe.',
            //         sessionName: session.data.name,
            //         sessionType: session.data.type
            //     });
            // }

            // // check if the customer has already checked in
            // let customer = session.data.checkIn.items.find((i) => i.customerID == this.data.id)

            // if(!_st.isNUE(customer)) {
            //     return Promise.reject({
            //         error: true,
            //         code: 'alreadyin',
            //         goto: '',
            //         message: 'Ya hiciste check-in a esta sesión.',
            //         sessionName: session.data.name,
            //         sessionType: session.data.type
            //     });
            // }

            // // check if customer has sessions available
            // let subs = await this.getSubscriptions()
            // let subscription = null
            // for(let s of subs) {
            //     if (s.remainingSessions < 1 || !this.isInsideGracePeriod(s.nextPaymentDate)) continue

            //     // load order
            //     let order = new PetuOrder()
            //     await order.load(s.orderID)

            //     let product = order.data.products.find((p) => p.productId == s.productID)
            //     if(product.trainingType == session.data.type) {
            //         subscription = s
            //         break
            //     }
            // }
            
            // if(_st.isNU(subscription)) {
            //     // Send notification to admin
            //     API.post('petuRestApi', '/invalid-checkin', {
            //         body: { email: this.data.email, firstName: this.data.firstName, lastName: this.data.lastName }
            //     });

            //     return Promise.reject({
            //         error: true,
            //         code: 'nosubscription',
            //         goto: '',
            //         message: 'No tiene ninguna suscripción activa o su suscripción ha expirado.',
            //         sessionName: session.data.name,
            //         sessionType: session.data.type
            //     });
            // }
            
            // // do the check-in to the session
            // let c = await API.graphql(graphqlOperation(createCheckIn, { 
            //     input: {
            //         id              : uuidv4(),
            //         checkinDate     : this.now(),
            //         customerID      : this.data.id,
            //         sessionID       : sessionId,
            //         subscriptionID  : subscription.id
            //     } 
            // }));
            
            // // update the remaining sessions of the subscription
            // await API.graphql(graphqlOperation(updateCustomerSubscription, { 
            //     input: {
            //         id: subscription.id,
            //         remainingSessions: subscription.remainingSessions - 1
            //     } 
            // }));
            
            // return Promise.resolve({ 
            //     ...c.data.createCheckIn, 
            //     remainingSessions: subscription.remainingSessions - 1 ,
            //     sessionName: session.data.name,
            //     sessionType: session.data.type,
            //     product: subscription.product
            // });
        } 
        catch (error) {
            console.log('Error in check-in', error)
            return Promise.reject(error);
        }
    }
    async isEmailInUse() {
        let customers = store.getters['auth/isLoggedIn'] === true ? 
            await API.graphql(graphqlOperation(customerByEmail, { email: this.data.email })) : 
            await API.graphql({ query: customerByEmail, variables: { email: this.data.email }, authMode: 'API_KEY' });

        return customers.data.customerByEmail.items.length > 0;
    }

    isInsideGracePeriod(date) {
        // return new Date(date) > Date.now() - 1209600000
        return Date.now() <= new Date(date);
    }

    async isAdmin() {
        // let customer = store.getters['auth/isLoggedIn'] === true ? 
        //     await API.graphql(graphqlOperation(getCustomerAdmin, { id: this.data.id })) : 
        //     await API.graphql({ query: getCustomerAdmin, variables: { id: this.data.id }, authMode: 'API_KEY' });

        // return _st.isNUE(customer.data.getCustomer) ? false : customer.data.getCustomer.isAdmin;
        return this.data.isAdmin;
    }

    async isPetuPunchAdmin() {
        // let customer = store.getters['auth/isLoggedIn'] === true
        // ? await API.graphql(graphqlOperation(getPetuPunchAdmin, { id: this.data.id }))
        // : await API.graphql({ query: getPetuPunchAdmin, variables: { id: this.data.id }, authMode: 'API_KEY' })

        // return _st.isNUE(customer.data.getCustomer) ? false : customer.data.getCustomer.isPetuPunchAdmin
        return this.data.isPetuPunchAdmin;
    }

    hasVacuID() {
        return !_st.isNUE(this.data.vacuId) && !_st.isNUE(this.data.vacuId.bucket) && !_st.isNUE(this.data.vacuId.key) && !_st.isNUE(this.data.vacuId.region)
    }

    now() {
        var tzoffset = (new Date()).getTimezoneOffset() * 60000; // offset in milliseconds
        return (new Date(Date.now() - tzoffset)).toISOString(); // => '2015-01-26T06:40:36.181'
    }
}