import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import store from './store'
import router from './router'
import Amplify from 'aws-amplify';
import '@aws-amplify/ui-vue';
import awsExports from './aws-exports';

require('@/assets/styles/main.css');

// manage multiple signin - signout urls
const { NODE_ENV } = process.env;

const [
  localRedirectSignIn,
  productionRedirectSignIn,
] = awsExports.oauth.redirectSignIn.split(",");

const [
  localRedirectSignOut,
  productionRedirectSignOut,
] = awsExports.oauth.redirectSignOut.split(",");

const updatedAwsConfig = {
  ...awsExports,
  oauth: {
    ...awsExports.oauth,
    redirectSignIn: NODE_ENV === 'development' ? localRedirectSignIn : productionRedirectSignIn,
    redirectSignOut: NODE_ENV === 'development' ? localRedirectSignOut : productionRedirectSignOut,
  }
}

Amplify.configure(updatedAwsConfig);

export const bus = new Vue();

Vue.config.productionTip = false;

new Vue({
  vuetify,
  store,
  router,
  render: h => h(App)
}).$mount('#app')
