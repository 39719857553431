import { PetuCoupon } from '@/classes/coupon'

export const couponsInfo = {
    namespaced: true,
    state: {
        coupons     : null,
    },
    mutations: {
        setCoupons( state, payload ) {
            state.coupons = payload;
        },
    },
    actions: {
        async getCouponsData({ commit }) {
            let petuCoupon = new PetuCoupon();
            const couponsData = await petuCoupon.getList();
            commit( 'setCoupons', couponsData );
        },
    },
    getters: {
        coupons: (state) => state.coupons
    }
}