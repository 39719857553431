import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary     : '#ee3a8c',
                secondary   : '#f68926',
                accent      : '#639d43'
            }
        }
    }
});
